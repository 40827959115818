import {CellKeyDownEvent, CellPosition, Column, GridApi} from 'ag-grid-community';
import {GridPreviousEditedCell} from '../../models-old/ngp-report-grid/grid';
import {Store} from '@ngrx/store';
import {setNextPageForSharedGrid} from '../../../features/stock-manager/store/stock-manager.actions';

/**
 * @class GridNavigationUtils
 * Utilities for manipulating grid navigation etc. settings.
 *
 * @member gridNavigateToNextRowCell Navigate to the next rows cell directly below it.
 * @member startEditingCell Start and focus editing a cell within the AG Grid.
 * @member stopEditingCellFromCellKeyEvent Stop editing a grid cell based on a cell key down event.
 *
 */
export class GridNavigationUtils {

  /**
   * Navigate to the next rows cell directly below it.
   * this will use the enter key and move downwards one cell.
   * this also navigates to the next page if the last cell of the page is used.
   * The last cell on the last page will move the focus to the first cell on the first page.
   *
   * @param gridApi A reference to the Grid API for AG Grid.
   * @param cellKeyDownEvent A `CellKeyDownEvent` that is triggered.
   * @param previousEditedCell The previous edited cell information.
   *
   * @param isStockManager
   * @param store
   * @returns If cells are being edited it will return the new last edited cell otherwise it will return the last edited cell location.
   *
   */
  static gridNavigateToNextRowCell(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
    store: Store,
  ): GridPreviousEditedCell {
    const editingCells: CellPosition[] = gridApi.getEditingCells();
    if (editingCells.length > 0) {
      previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
      const nextRowIndex = cellKeyDownEvent.rowIndex + 1;
      const pageLastRowIndex = (gridApi.paginationGetPageSize()) * (gridApi.paginationGetCurrentPage() + 1) - 1;
      const lastAvailableRowIndex = gridApi.getModel().getRowCount() - 1; // Todo: Deprecated in future to change how pagination works
      GridNavigationUtils.getEditingColumns(gridApi);
      if (nextRowIndex <= lastAvailableRowIndex) {
        if (nextRowIndex <= pageLastRowIndex) {
          GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, nextRowIndex);
        } else {
          gridApi.paginationGoToNextPage();
          GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, GridNavigationUtils.firstEnabledRowIndexOnCurrentPage(gridApi));
        }
      } else {
        gridApi.paginationGoToFirstPage();
        GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, 0);
      }
      return previousEditedCell;
    }
  }

  static gridNavigateToNextRowFirstCell(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
    store: Store,
  ): GridPreviousEditedCell {
    const editingCells: CellPosition[] = gridApi.getEditingCells();
    if (editingCells.length > 0) {
      previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
      const nextRowIndex = cellKeyDownEvent.rowIndex + 1;
      const pageLastRowIndex = (gridApi.paginationGetPageSize()) * (gridApi.paginationGetCurrentPage() + 1) - 1;
      const lastAvailableRowIndex = gridApi.getModel().getRowCount() - 1; // Todo: Deprecated in future to change how pagination works
      const currentColumnId = cellKeyDownEvent.column.getColId();
      const editingColumns = GridNavigationUtils.getEditingColumns(gridApi);
      const currentColumnIndex = editingColumns.findIndex(col => col['colId'] === currentColumnId);
      if (nextRowIndex <= lastAvailableRowIndex) {
        if (nextRowIndex <= pageLastRowIndex) {
          const nextColumn = editingColumns[currentColumnIndex + 1];
          GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, nextRowIndex, nextColumn['colId']);
        } else {
          gridApi.paginationGoToNextPage();
          GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, GridNavigationUtils.firstEnabledRowIndexOnCurrentPage(gridApi));
        }
      } else {
        gridApi.paginationGoToFirstPage();
        GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, 0);
      }
      return previousEditedCell;
    }
  }


  static jumpBackToFirstFieldOnCurrentPage(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
  ): GridPreviousEditedCell {
    previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
    GridNavigationUtils.startEditingCellInFirstEditableRow(gridApi, GridNavigationUtils.firstEnabledRowIndexOnCurrentPage(gridApi));
    return previousEditedCell;
  }

  static restartAtTheTopOfTheColumn(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
  ): GridPreviousEditedCell {
    previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
    GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, GridNavigationUtils.firstEnabledRowIndexOnCurrentPage(gridApi));
    return previousEditedCell;
  }

  static startTheNextColumn(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
  ): GridPreviousEditedCell {
    previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
    GridNavigationUtils.startEditingNextEditableColumn(gridApi, cellKeyDownEvent, GridNavigationUtils.firstEnabledRowIndexOnCurrentPage(gridApi));
    return previousEditedCell;
  }

  static goToFirstFieldOnNextPage(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
    store: Store,
  ): GridPreviousEditedCell {
    const nextRowIndex = cellKeyDownEvent.rowIndex + 1;
    const pageLastRowIndex = (gridApi.paginationGetPageSize()) * (gridApi.paginationGetCurrentPage() + 1) - 1;
    const lastAvailableRowIndex = gridApi.getModel().getRowCount() - 1;
    previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
    if (isStockManager) {
      store.dispatch(setNextPageForSharedGrid());
      setTimeout(() => {
        GridNavigationUtils.startEditingCellInFirstEditableRow(gridApi, 0);
      }, 100);
    } else if (nextRowIndex <= lastAvailableRowIndex) {
      gridApi.paginationGoToNextPage();
      GridNavigationUtils.startEditingCellInFirstEditableRow(gridApi, pageLastRowIndex + 1);
    } else {
      gridApi.paginationGoToFirstPage();
      GridNavigationUtils.startEditingCellInFirstEditableRow(gridApi, 0);
    }
    return previousEditedCell;
  }

  static continueTheColumnOnNextPage(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    previousEditedCell: GridPreviousEditedCell,
    isStockManager: boolean,
    store: Store,
  ): GridPreviousEditedCell {
    const nextRowIndex = cellKeyDownEvent.rowIndex + 1;
    const pageLastRowIndex = (gridApi.paginationGetPageSize()) * (gridApi.paginationGetCurrentPage() + 1) - 1;
    const lastAvailableRowIndex = gridApi.getModel().getRowCount() - 1;
    previousEditedCell = GridNavigationUtils.stopEditingCellFromCellKeyEvent(gridApi, cellKeyDownEvent);
    if (isStockManager) {
      store.dispatch(setNextPageForSharedGrid());
      setTimeout(() => {
        GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, 0);
      }, 100);
    } else if (nextRowIndex <= lastAvailableRowIndex) {
      GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, pageLastRowIndex + 1);
    } else {
      gridApi.paginationGoToFirstPage();
      GridNavigationUtils.startEditingCell(gridApi, cellKeyDownEvent, 0);
    }
    return previousEditedCell;
  }

  /**
   * Check if the current cell is the last cell in the visible column.
   *
   * @param gridApi A reference to the Grid API for AG Grid.
   * @param cellKeyDownEvent A `CellKeyDownEvent` that is triggered.
   *
   * @returns `true` if the current cell is the last cell in the visible column, `false` otherwise.
   */
  static isLastVisibleCellInColumn(gridApi: GridApi, cellKeyDownEvent: CellKeyDownEvent): boolean {
    const rowCount = gridApi.getModel().getRowCount();
    const pageLastRowIndex = (gridApi.paginationGetPageSize()) * (gridApi.paginationGetCurrentPage() + 1) - 1;
    if (cellKeyDownEvent.rowIndex === pageLastRowIndex) {
      return true;
    }
    return cellKeyDownEvent.rowIndex === rowCount - 1;
  }

  static startEditingCellInFirstEditableRow(
    gridApi: GridApi,
    rowIndex: number,
  ): void {
    if (gridApi) {
      const firstColumn: string = GridNavigationUtils.getEditingColumns(gridApi)[0].getColId();
      gridApi.setFocusedCell(rowIndex, firstColumn);
      gridApi.startEditingCell({rowIndex, colKey: firstColumn});
    }
  }

  /**
   * Stop editing a grid cell based on a cell key down event.
   *
   * @param gridApi A reference to the Grid API for AG Grid.
   * @param cellKeyDownEvent A `CellKeyDownEvent` that is triggered.
   *
   */
  static stopEditingCellFromCellKeyEvent(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
  ): GridPreviousEditedCell {
    gridApi.stopEditing(true);
    return {
      rowIndex: cellKeyDownEvent.rowIndex,
      columnIndex: cellKeyDownEvent.column.getColId(),
    };
  }

  static getEditingColumns(gridApi: GridApi): Column[] {
    const displayedColumns = gridApi.getAllDisplayedColumns();
    const editingColumns = displayedColumns.filter((column: Column) => {
      const colDef = column.getColDef();
      return colDef && colDef.editable === true;
    });
    return editingColumns;
  }

  static firstEnabledRowIndexOnCurrentPage(gridApi: GridApi): number | null {
    const currentPageIndex = gridApi.paginationGetCurrentPage();
    const pageSize = gridApi.paginationGetPageSize();
    const startRowIndex = currentPageIndex * pageSize;
    const endRowIndex = Math.min(startRowIndex + pageSize, gridApi.getModel().getRowCount());

    // Iterate through rows on the current page to find the first enabled row
    for (let rowIndex = startRowIndex; rowIndex < endRowIndex; rowIndex++) {
      const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
      if (rowNode && !rowNode.data.disabled) {
        return rowIndex; // Return the index of the first enabled row
      }
    }

    return null; // Return null if no enabled row is found on the current page
  }

  /**
   * Start and focus editing a cell within the AG Grid.
   *
   * @param gridApi A reference to the Grid API for AG Grid.
   * @param cellKeyDownEvent A `CellKeyDownEvent` that is triggered.
   * @param rowIndex The index of the cell row that needs to be focused.
   * @param nextColKey The next column that needs to be focused on.
   */
  private static startEditingCell(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    rowIndex: number,
    nextColKey?: string,
  ): void {
    if (!nextColKey) {
      const colKey: string = cellKeyDownEvent.column.getColId();
      gridApi.startEditingCell({rowIndex, colKey});
    } else {
      gridApi.startEditingCell({rowIndex, colKey: nextColKey});
    }

  }

  private static startEditingNextEditableColumn(
    gridApi: GridApi,
    cellKeyDownEvent: CellKeyDownEvent,
    rowIndex: number,
  ): void {
    const editingColumns = GridNavigationUtils.getEditingColumns(gridApi);
    const colKey: string = cellKeyDownEvent.column.getColId();

    const currentColIndex = editingColumns.findIndex((column: Column) => column.getColId() === colKey);
    const nextColIndex = currentColIndex + 1;
    const nextColumn = nextColIndex < editingColumns.length ? editingColumns[nextColIndex] : editingColumns[currentColIndex];

    const nextColId = nextColumn.getColId();
    gridApi.startEditingCell({rowIndex, colKey: nextColId});
  }

  private static firstRowIndexOnCurrentPage(gridApi: GridApi): number {
    const currentPageIndex = gridApi.paginationGetCurrentPage();
    const pageSize = gridApi.paginationGetPageSize();
    return currentPageIndex * pageSize;
  }


}
