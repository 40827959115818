import {Component, Input} from '@angular/core';
import {
  SelectMainButton,
  SelectModifyButton,
  SelectPopoverComponent,
} from '../../../shared-module/components/select-popover/select-popover.component';
import {OverlayEventDetail} from '@ionic/core';
import {ISharedModalFiltersAdvanced} from '../../../../shared/shared-models/modals/shared-modal-filters-advanced';
import {
  SharedModalFiltersAdvancedContainerComponent,
} from '../../../../shared/shared-components/components/shared-modal-filters-advanced-container/shared-modal-filters-advanced-container.component';
import {ColDef} from 'ag-grid-community';
import {SaveUserSettingsPage} from '../../../shared-module/modules-old/save-user-settings/save-user-settings.page';
import {
  setStockManagerVisibility,
  togglePriceBanding,
} from '../../../../features/stock-manager/store/stock-manager.actions';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {ModalController, PopoverController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {stockManagerGridColDefs} from '../../../../features/stock-manager/models/stock-manager-grid-col-def';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';
import {ISharedModalBasic} from '../../../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../../shared-icons/icons';
import {
  SharedModalBasicComponent,
} from '../../../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {setFitToGrid} from '../../../../features/settings/store/settings.actions';
import {
  ngpReportGridColDefs,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/ngp-report/ngp-report-grid-col-defs';
import {
  setNGPReportFiltersAndTools,
  setNGPReportVisibility,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {first} from 'rxjs/operators';
import {
  selectFiltersAndToolsWithAppliedFilters,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {Observable} from 'rxjs';
import {FiltersAndTools} from '../../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {
  SettingsUserContainerComponent,
} from '../../../../features/settings/components/settings-user/settings-user-container/settings-user-container.component';

@Component({
  selector: 'app-stock-manager-input-container',
  templateUrl: './shared-grid-table-settings.component.html',
  styleUrls: ['./shared-grid-table-settings.component.scss'],
})
export class SharedGridTableSettingsComponent {
  @Input() visibleFields: { [p: string]: boolean };
  @Input() showAllColDef: boolean;
  @Input() isCurrentlyPriceBanding: boolean;
  stockManagerColDefs: ColDef[] = [...stockManagerGridColDefs];
  stockManagerColDefsDefault: ColDef[] = getDeepCopyOfObject(stockManagerGridColDefs);
  ngpReportColDefs: ColDef[] = [...ngpReportGridColDefs];
  ngpReportColDefsDefault: ColDef[] = getDeepCopyOfObject(ngpReportGridColDefs);
  colDefs: ColDef[] = [];
  colDefsDefault: ColDef[] = [];
  selectPopover: {
    order: string[]; selection: { [key: string]: string }; disabledKeys: string[]; value: string[];
  } = {
    order: [], selection: {}, disabledKeys: [], value: [],
  };
  currentSelectedPage: string;

  filtersAndTools$: Observable<FiltersAndTools>;

  protected readonly icons: typeof Icons = Icons;

  constructor(
    private modalController: ModalController,
    private readonly store: Store,
    private popoverController: PopoverController,
  ) {
  }

  @Input() set currentPage(currentPage: string) {
    this.currentSelectedPage = currentPage;
    switch (currentPage) {
      case'stock-manager':
        this.colDefs = [...this.stockManagerColDefs];
        this.colDefsDefault = getDeepCopyOfObject(this.stockManagerColDefsDefault);
        break;
      case'ngp-report':
        this.colDefs = [...this.ngpReportColDefs];
        this.filtersAndTools$ = this.store.select(selectFiltersAndToolsWithAppliedFilters);
        this.colDefsDefault = getDeepCopyOfObject(this.ngpReportColDefsDefault);
        break;
    }
  }


  async onVisibleFieldsClick(event: Event): Promise<void> {

    this.convertColumnDefinitionsToSelectPopover(this.colDefs);
    const popover: HTMLIonPopoverElement = await this.popoverController.create({
      component: SelectPopoverComponent, componentProps: {
        additionalMainButton: {
          text: 'Save/Load',
          handler: (currentSelection: string[]) => this.onVisibleFieldSaveButtonClick(currentSelection),
        } as SelectMainButton,
        cancelText: 'Cancel',
        disabled: this.selectPopover.disabledKeys,
        maxListHeight: 200,
        multiple: true,
        okText: 'Apply',
        order: this.selectPopover.order,
        title: 'Toggle Visible Fields',
        search: true,
        selectAll: true,
        selectModButtons: [{
          text: 'Default', handler: () => this.onVisibleFieldDefaultButtonClick(),
        }, {}] as SelectModifyButton[],
        selection: this.selectPopover.selection,
        value: this.selectPopover.value,
      }, event,
    });

    await popover.present();
    await popover.onDidDismiss().then((overlayEventDetail: OverlayEventDetail<string[]>): void => {
      this.toggleVisibleFields(overlayEventDetail);
      void this.popoverController.dismiss({showAllColDef: this.showAllColDef});
    });
  }

  async onAdvancedFiltersClick(): Promise<void> {
    await this.popoverController.dismiss({showAllColDef: this.showAllColDef});
    const componentProps: ISharedModalFiltersAdvanced = {
      buttonCloseCross: true,
      colDefs: this.colDefs,
      colDefsIgnore: ['disabled', 'icons'],
      gridReference: `${this.currentSelectedPage}`,
      modalTitle: `Create Advanced Filters for ${this.currentSelectedPage === 'stock-manager' ? 'Stock Manager' : 'NGP Report'}`,
    };
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalFiltersAdvancedContainerComponent,
      componentProps,
      cssClass: ['shared-modal-filters-advanced'],
    });
    await modalController.present();
    await modalController.onDidDismiss();
  }

  onToggleFitToTableClick(): void {
    this.store.dispatch(setFitToGrid({fitToGrid: !this.showAllColDef}));
  }

  async onTogglePriceBandingClick(event: Event): Promise<void> {
    const isPriceBanding = (event.target as HTMLInputElement).checked;
    if (this.isCurrentlyPriceBanding !== isPriceBanding) {
      if (this.isCurrentlyPriceBanding) {
        void this.onTriggerPriceBanding().then();
      }
      switch (this.currentSelectedPage) {
        case'stock-manager':
          this.store.dispatch(togglePriceBanding());
          break;
        case'ngp-report':
          const filtersAndTools = await this.filtersAndTools$.pipe(first()).toPromise();
          filtersAndTools.isApplyPriceBandingOn = !isPriceBanding;
          this.store.dispatch(setNGPReportFiltersAndTools({filtersAndTools: filtersAndTools}));
          break;
      }
    } else {
      switch (this.currentSelectedPage) {
        case'stock-manager':
          this.store.dispatch(togglePriceBanding());
          break;
        case'ngp-report':
          const filtersAndTools = await this.filtersAndTools$.pipe(first()).toPromise();
          filtersAndTools.isApplyPriceBandingOn = !isPriceBanding;
          this.store.dispatch(setNGPReportFiltersAndTools({filtersAndTools: filtersAndTools}));
          break;
      }
    }
  }

  onEditTabNavClick(): void {
    void this.showSettings('tableNav').then();
  }

  async showSettings(accordionToOpen: string): Promise<void> {
    void this.popoverController.dismiss().then();
    const modal = await this.modalController.create({
      component: SettingsUserContainerComponent,
      componentProps: {openAccordion: accordionToOpen},
      backdropDismiss: false,
    });
    await modal.present();
  }

  async onSetPreviewColumnsClick(fieldEvent: Event): Promise<void> {
    void this.showSettings(this.currentSelectedPage === 'stock-manager' ? 'stockManagerPreviewColumns' : 'ngpPreviewColumns').then();
  }

  private onVisibleFieldDefaultButtonClick(): {
    [p: string]: boolean
  } {
    const checked: { [sKey: string]: boolean } = {};
    this.colDefsDefault = [...this.colDefsDefault];
    this.colDefsDefault.forEach((colDef: ColDef): void => {
      checked[colDef.field] = !colDef.hide;
    });
    return checked;
  }

  private async onTriggerPriceBanding(): Promise<void> {
    const componentProps: ISharedModalBasic = {
      buttonAccept: true,
      buttonAcceptText: 'Accept',
      buttonClose: false,
      buttonCloseCross: false,
      buttonCloseText: ``,
      modalTitle: 'Important Notice on Edited Items',
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'NOTE: You are about to enable price banding.',
      contextDescription: [
        'You are enabling Price Banding to ensure price consistency.',
        '',
        'Price banding will be applied to all currently edited items, all edited values will be affected.',
        'This can be undone, while changes are not saved, by disabling price banding.',
      ],
    };
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent, componentProps, cssClass: ['shared-basic-modal-css'],
    });
    await modalController.present();
  }

  private async onVisibleFieldSaveButtonClick(currentSelection: string[]): Promise<void> {
    const visibleFieldsToSave = {};
    this.colDefs.forEach((colDef: ColDef): void => {
      const found: number = currentSelection.findIndex((field: string): boolean => field === colDef.field);
      visibleFieldsToSave[colDef.field] = !(found > -1);
    });
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SaveUserSettingsPage, componentProps: {
        getSaveObj: (() => visibleFieldsToSave),
        document: 'visible-fields',
        what: `Visible Fields for ${this.currentSelectedPage === 'stock-manager' ? 'Stock Manager' : 'NGP Report'}`,
        entryPrefix: 'ag-grid',
        entrySuffix: this.currentSelectedPage,
        colDefs: this.colDefs,
      },
    });
    await modalController.present();
    const loadResponse: OverlayEventDetail<{
      colDefs: string[],
      data: ColDef[]
    }> = await modalController.onDidDismiss();
    if (loadResponse?.data.data) {
      const storageKey = this.currentSelectedPage === 'stock-manager'
        ? 'stock-manager-col-def-order'
        : 'ngp-report-col-def-order';
      if (loadResponse.data.colDefs) {
        localStorage.setItem(storageKey, JSON.stringify(loadResponse.data.colDefs));
      }
      this.colDefs.forEach((col: ColDef): void => {
        col = {...col};
        col.hide = loadResponse.data[col.field];
      });
      switch (this.currentSelectedPage) {
        case'stock-manager':
          this.store.dispatch(setStockManagerVisibility({
            colDef: [...this.colDefs],
            menuData: Object.keys(loadResponse.data.data).filter((key: string) => !loadResponse.data.data[key]),
          }));
          localStorage.setItem(`stock-manager-visible-fields-preferences`, JSON.stringify({
            document: 'visible-fields',
            entryPrefix: 'ag-grid',
            entrySuffix: 'stock-manager',
            entry: 'Auto set via Load',
            data: loadResponse.data.data,
            timestamp: new Date(),
          }));
          break;
        case'ngp-report':
          this.store.dispatch(setNGPReportVisibility({
            colDef: [...this.colDefs],
            menuData: Object.keys(loadResponse.data.data).filter((key: string) => !loadResponse.data.data[key]),
          }));
          localStorage.setItem(`ngp-report-visible-fields-preferences`, JSON.stringify({
            document: 'visible-fields',
            entryPrefix: 'ag-grid',
            entrySuffix: 'ngp-report',
            entry: 'Auto set via Load',
            data: loadResponse.data.data,
            timestamp: new Date(),
          }));
          break;
      }
      this.colDefs = GridUtils.getColumnDefsFromLocalStorage(this.colDefs, storageKey);
    }
  }

  private convertColumnDefinitionsToSelectPopover(colDefs: ColDef[]): void {
    this.selectPopover.selection = {};
    this.selectPopover = GridUtils.convertColumnDefinitionsToSelectPopover(colDefs, this.visibleFields);
  }

  private toggleVisibleFields(event: OverlayEventDetail<string[]>): void {
    if ((event?.role === 'ok' || event?.role === 'ok-all') && event?.data?.length > 0) {
      const localData: { [field: string]: boolean } = {};
      this.colDefs = [...this.colDefs];
      this.colDefs.forEach((col: ColDef): void => {
        col = {...col};
        const fieldIndex = event.data.findIndex((index: string) => index === col.field);
        col.hide = fieldIndex === -1;
        localData[col.field] = col.hide;
        localStorage.setItem(`${this.currentSelectedPage}-visible-fields-preferences`, JSON.stringify({
          document: 'User manually set',
          prefix: null,
          suffix: null,
          entry: event.role === 'ok-all' ? 'Applied all fields' : 'Applied custom fields',
          data: localData,
          timestamp: new Date(),
        }));
      });
      if (this.currentSelectedPage === 'stock-manager') {
        this.store.dispatch(setStockManagerVisibility({
          colDef: [...this.colDefs], menuData: event.data,
        }));
      } else {
        this.store.dispatch(setNGPReportVisibility({
          colDef: [...this.colDefs], menuData: event.data,
        }));
      }

    }
  }

}
