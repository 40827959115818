import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {StockItem} from '../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-cell-renderer-multi-select-array',
  templateUrl: './cell-renderer-multi-select-array.component.html',
  styleUrls: ['./cell-renderer-multi-select-array.component.scss'],
})
export class CellRendererMultiSelectArrayComponent implements ICellRendererAngularComp {

  value: string[] | string;
  selectedValues: string[] = [];
  params: ICellRendererParams;
  width: string;
  type: string;
  currentPage$: Observable<string>;
  currentPage: string;

  constructor(
    private readonly store: Store,
  ) {
  }

  get selectedValuesLabel(): string {
    if (!this.selectedValues || this.selectedValues.length === 0) {
      return '';
    }

    const firstValue = this.selectedValues[0];
    const additionalCount = this.selectedValues.length - 1;

    return additionalCount > 0
      ? `${firstValue} + ${additionalCount} other${additionalCount > 1 ? 's' : ''}`
      : `${firstValue}`;
  }

  agInit(params: ICellRendererParams): void {
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
    this.value = params.value;
    this.selectedValues = params.value
      ? String(params.value)
        .split(',')
        .filter((val: string) => val.trim() !== '')
      : [];

    this.type = params.colDef.field;
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.params.colDef.field] = {...params.data.originalValue[this.params.colDef.field]};
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.params.colDef.field].value;
    this.params.data[this.params.colDef.field] = this.value;
    this.selectedValues = this.params.data.originalValue[this.params.colDef.field].value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, this.params.colDef.field, this.store, this.currentPage);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  formatSelectedValues(values: string[]): string {
    return values.map((value: string) => `${value}`).join(', \n');
  }
}

