import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import * as NGPReportActions from './ngp-report.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {
  FiltersAndTools,
  filtersAndToolsDefault,
} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FilterAndToolUtils} from '../../../shared-utilities/models-old/utils-old/filterAndToolUtils';
import {
  DisabledRules,
  LineColour,
  StockItem,
  Supplier,
  VatRates,
} from '../../../shared-utilities/models-old/datastructures';
import {ReportUtils} from '../../../shared-utilities/utils-old/shared-utils-old/report-utils';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {ColDef} from 'ag-grid-community';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {IStore} from '../../../shared/shared-models/store/store';
import {PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS} from '../../../shared/shared-utils/price/price-banding.utils';
import * as NGPReportUtils from './ngp-report.reducer.utils';
import {checkForDisabledItems, mergeNgpReportsFromLocalStorage} from './ngp-report.reducer.utils';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {IError} from '../../../shared-utilities/models-old/error/error';

export interface NGPReportState extends IInitialState {
  // NGP Report------------------------------
  isNGPReportsLoading: boolean;
  ngpReports: { [storeId: string]: { [stockId: string]: NGPReport } };
  ngpReportsNewlyAdded: { [storeId: string]: { [stockId: string]: NGPReport } };
  // Sub Header -----------------------------
  filtersAndTools: FiltersAndTools;
  // Line Colours ---------------------------
  isLineColourLoading: boolean;
  lineColours: { [storeId: string]: LineColour } | null;
  // Stock Items ----------------------------
  isStockItemsLoading: boolean;
  stockItems: { [storeId: string]: StockItem[] };
  // Grid Function --------------------------
  ngpReportGridColDefs: { [storeId: string]: ColDef[] };
  visibleFields: { [key: string]: boolean };
  previewColumnData: { [key: number]: boolean },
  // Edited and sorting State for column ----
  headerMenuData: StoreHeaderMenuData;
  // Store Departments ----------------------
  isDepartmentsLoading: boolean;
  departments: { [storeId: string]: IDepartment[] };
  // Suppliers ------------------------------
  isStoreSuppliersLoading: boolean;
  storeSuppliers: { [storeId: string]: { [key: string]: Supplier } };
  // Vat Rates ------------------------------
  vatRates: { [storeId: string]: VatRates };
  // Disabled Rules
  disabledRules: { [storeId: string]: DisabledRules };
}

export const initialNGPReportState: NGPReportState = {
  // Initial --------------------------------
  errors: [],
  // NGP Report------------------------------
  isNGPReportsLoading: false,
  ngpReports: {},
  ngpReportsNewlyAdded: {},
  // Sub Header -----------------------------
  filtersAndTools: filtersAndToolsDefault,
  // Line Colours ---------------------------
  isLineColourLoading: false,
  lineColours: null,
  // Stock Items ----------------------------
  isStockItemsLoading: false,
  stockItems: {},
  // Grid Function --------------------------
  ngpReportGridColDefs: {},
  visibleFields: {},
  previewColumnData: {},
  // Edited and sorting State for column ----
  headerMenuData: {},
  // Store Departments ----------------------
  isDepartmentsLoading: false,
  departments: {},
  // Suppliers ------------------------------
  isStoreSuppliersLoading: false,
  storeSuppliers: {},
  // Vat Rates ------------------------------
  vatRates: {},
  // Disabled Rules
  disabledRules: {},
};

const createCoreReducer: ActionReducer<NGPReportState> = createReducer(
  initialNGPReportState,
  // ====================================================================================================
  // Get Line Colours
  // ====================================================================================================
  on(NGPReportActions.getLineColours, (state: NGPReportState, action) => ({
    ...state,
    isLineColourLoading: true,
  })),
  on(NGPReportActions.getLineColoursSuccess, (state: NGPReportState, {lineColours, selectedStore}) => {
    const ngpReportsForStore = state.ngpReports[selectedStore.storeId] || {};
    const updatedNgpReportsArray = GridUtils.applyLineColoursToNgpReports(
      lineColours,
      Object.values(ngpReportsForStore),
    );
    const updatedNgpReports = updatedNgpReportsArray.reduce((acc, report) => {
      acc[report.stockId] = report;
      return acc;
    }, {});
    return {
      ...state,
      isLineColourLoading: false,
      lineColours: {
        ...state.lineColours,
        [selectedStore.storeId]: lineColours,
      },
      ngpReports: {
        ...state.ngpReports,
        [selectedStore.storeId]: updatedNgpReports,
      },
    };
  }),
  on(NGPReportActions.getLineColoursFailure, (state: NGPReportState, {error}) => ({
    ...state,
    isLineColourLoading: false,
    lineColours: null,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsLineColoursLoading, (state: NGPReportState, {isLoading}) => ({
    ...state,
    isLineColourLoading: isLoading,
  })),
  // ====================================================================================================
  // Get Stock Items
  // ====================================================================================================
  on(NGPReportActions.getStockItem, (state: NGPReportState) => ({
    ...state,
    isStockItemsLoading: true,
    stockItems: null,
  })),
  on(NGPReportActions.getStockItemSuccess, (state: NGPReportState, {stockItems, store}) => {
    const existingReports = state.ngpReports[store.storeId] || {};
    let caughtError: IError = {} as IError;
    if (Object.keys(existingReports).length < 1) {
      stockItems = stockItems.map((item: StockItem) => ({
        ...item,
        newlyAdded: false,
      }));
    }
    let data = getStockItemSuccessCalculations(state, store, stockItems, true);
    const newReports = {};
    for (const report of Object.values(data.ngpReports)) {
      if (!existingReports[report.stockId]) {
        newReports[report.stockId] = report;
      }
    }
    data = getStockItemSuccessCalculations(state, store, stockItems, true, Object.values(newReports));
    data.ngpReports = checkForDisabledItems(
      stockItems,
      Object.values(data.ngpReports),
      state.disabledRules[store.storeId],
      true,
    ).reduce((acc, report) => {
      acc[report.stockId] = report;
      return acc;
    }, {});

    if (Object.keys(existingReports).length > 0) {
      data.filtersAndTools.newItemCount = Object.keys(newReports).length;
    }

    data.ngpReports = mergeNgpReportsFromLocalStorage(store.storeId, data.ngpReports, 'savedNgpReportEdits');
    return {
      ...state,
      isStockItemsLoading: false,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: stockItems,
      },
      ngpReports: {
        ...state.ngpReports,
        [store.storeId]: Object.keys(existingReports).length < 1 ? data.ngpReports : existingReports,
      },
      ngpReportsNewlyAdded: {
        ...state.ngpReportsNewlyAdded,
        [store.storeId]: newReports,
      },
      filtersAndTools: data.filtersAndTools,
      errors: [...state.errors, caughtError],
    };
  }),
  on(NGPReportActions.getStockItemFailure, (state: NGPReportState, {error}) => ({
    ...state,
    isStockItemsLoading: false,
    stockItems: {},
    ngpReports: {},
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsStockItemLoading, (state: NGPReportState, {isLoading}) => ({
    ...state,
    isStockItemsLoading: isLoading,
  })),
  // ====================================================================================================
  // Get Items Disabling Rules
  // ====================================================================================================
  on(NGPReportActions.getStockItemDisablingRulesSuccess, (state: NGPReportState, {store, rulesDoc}) => {
    return {
      ...state,
      disabledRules: {
        ...state.disabledRules,
        [store.storeId]: rulesDoc,
      },
    };
  }),
  on(NGPReportActions.getStockItemDisablingRulesFailure, (state: NGPReportState, {errors}) => ({
    ...state,
    errors: [...state.errors, errors],
  })),
  on(NGPReportActions.setItemDisabledWithStoreID, (state: NGPReportState, {ngpReport, store}) => {
    return {
      ...state,
      ngpReports: {
        ...state.ngpReports,
        [store.storeId]: {
          ...state.ngpReports[store.storeId],
          [ngpReport.stockId]: {
            ...state.ngpReports[store.storeId][ngpReport.stockId],
            ...ngpReport,
          },
        },
      },
    };
  }),
  // ====================================================================================================
  // Update NGP Report
  // ====================================================================================================
  on(NGPReportActions.updateSingleNGPReportWithStoreId, (state: NGPReportState, {ngpReport, storeId}) => {
    const updatedNgpReports = {
      ...state.ngpReports[storeId],
      [ngpReport.stockId]: {
        ...state.ngpReports[storeId][ngpReport.stockId],
        ...ngpReport,
      },
    };

    const amountEdited = Object.values(updatedNgpReports).filter((report: NGPReport) => report['isEdited']).length;
    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: {
        ...state.ngpReports,
        [storeId]: updatedNgpReports,
      },
    };
  }),
  on(NGPReportActions.updateAllNGPReportsWithStoreID, (state: NGPReportState, {ngpReports, storeId}) => {
    const ngpReportsMap = ngpReports.reduce((acc, report) => {
      acc[report.stockId] = report;
      return acc;
    }, {});
    const updatedReportsByStore = {...state.ngpReports[storeId], ...ngpReportsMap};
    const amountEdited = Object.values(updatedReportsByStore).filter((report: NGPReport) => report['isEdited']).length;

    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: {
        ...state.ngpReports,
        [storeId]: updatedReportsByStore,
      },
    };
  }),
  on(NGPReportActions.updateIsSelectedForSingleNGPReportWithStoreId, (state: NGPReportState, {ngpReport, storeId}) => {
    const reports = state.ngpReports?.[storeId];
    const report = reports[ngpReport.stockId];
    if (!report || !reports) return state;

    const updatedReport = {...report, ...ngpReport};

    const amountEdited =
      state.filtersAndTools.editedCount + (ngpReport.isEdited ? 1 : 0) - (report.isEdited ? 0 : 1);
    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: {
        ...state.ngpReports,
        [storeId]: {
          ...reports,
          [ngpReport.stockId]: updatedReport,
        },
      },
    };
  }),

  // ====================================================================================================
  // Set Filters and Tools
  // ====================================================================================================
  on(NGPReportActions.setNGPReportFiltersAndToolsWithStore, (state: NGPReportState, {filtersAndTools, store}) => {
    const filtersAndToolsUpdated = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools);
    const storeHeaderMenuData = NGPReportUtils.setNGPReportStoreHeaderMenuDataFromFiltersAndToolsForPriceBanding(
      filtersAndToolsUpdated,
      PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
      state.headerMenuData,
      store,
    );
    return {
      ...state,
      filtersAndTools: filtersAndToolsUpdated,
      headerMenuData: storeHeaderMenuData,
    };
  }),
  on(NGPReportActions.setNewlyAddedItemsVisibilityWithStoreID, (state: NGPReportState, {storeId}) => {
    const ngpReports = addNewlyAddedItemsToNgpReports(
      state.ngpReports[storeId],
      state.ngpReportsNewlyAdded[storeId],
    );
    let filtersAndTools: FiltersAndTools = state.filtersAndTools;
    if (Object.keys(ngpReports).length > 0) {
      filtersAndTools = FilterAndToolUtils.determineFiltersAndTools(Object.values(ngpReports), filtersAndTools);
      filtersAndTools = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools);
    }
    filtersAndTools.total =
      Object.keys(state.ngpReportsNewlyAdded[storeId]).length + Object.keys(state.ngpReports[storeId]).length;
    return {
      ...state,
      filtersAndTools,
      ngpReportsNewlyAdded: {
        ...state.ngpReportsNewlyAdded,
        [storeId]: {},
      },
      ngpReports: {
        ...state.ngpReports,
        [storeId]: ngpReports,
      },
    };
  }),

  // ====================================================================================================
  // Set Grid Column Definitions
  // ====================================================================================================
  on(NGPReportActions.setNGPReportGridColDefsWithStoreID, (state: NGPReportState, {colDefs, storeId}) => ({
    ...state,
    ngpReportGridColDefs: {
      ...state.ngpReportGridColDefs,
      [storeId]: colDefs,
    },
  })),

  // ====================================================================================================
  // Set Editing and Sorting for Column
  // ====================================================================================================
  on(NGPReportActions.setNGPReportMenuActionsWithStore, (state: NGPReportState, {store, gridHeaderMenu}) => {
    const headerMenuData: StoreHeaderMenuData = NGPReportUtils.setNGPReportStoreHeaderMenuActions(
      store,
      state.headerMenuData,
      PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
      gridHeaderMenu,
    );
    const filtersAndTools = NGPReportUtils.setFiltersAndToolsPropertyByHeaderMenuActionTypeChange<boolean>(
      state.filtersAndTools,
      'isApplyPriceBandingOn',
      gridHeaderMenu.typeOfChange,
      gridHeaderMenu.value,
    );
    return {
      ...state,
      filtersAndTools,
      headerMenuData,
    };
  }),
  // ====================================================================================================
  // Set Visible Fields
  // ====================================================================================================
  on(NGPReportActions.setNGPReportVisibility, (state: NGPReportState, {colDef, menuData}) => {
    const updatedVisibleFields = {...state.visibleFields};
    colDef?.forEach((item) => {
      const fieldIndex = menuData?.findIndex((index: string) => index === item.field);
      if (
        item.field === 'icons' ||
        item.field === 'stockId' ||
        item.field === 'desc' ||
        item.field === 'isSelected' ||
        item.field === 'diffGP'
      ) {
        updatedVisibleFields[item.field] = true;
      } else {
        updatedVisibleFields[item.field] = !!menuData?.[fieldIndex];
      }
    });
    return {
      ...state,
      visibleFields: updatedVisibleFields,
    };
  }),
  on(NGPReportActions.updateSingleVisibleField, (state: NGPReportState, {colId, value}) => ({
    ...state,
    visibleFields: {
      ...state.visibleFields,
      [colId]: value,
    },
  })),

//===============================================================================================================
// Set the NGP Preview Column Data
//===============================================================================================================
  on(NGPReportActions.setNgpPreviewColumnsSuccess, (state, {columnData}) => {
    const newVisibleFields = {...state.visibleFields};
    const columnFields = Object.values(columnData);
    Object.keys(newVisibleFields).forEach((field: string) => {
      newVisibleFields[field] = columnFields.includes(field);
    });
    return {
      ...state,
      previewColumnData: newVisibleFields,
    };
  }),
  on(NGPReportActions.setNgpPreviewColumnsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  // ====================================================================================================
  // Get Store Departments
  // ====================================================================================================
  on(NGPReportActions.getStoreDepartments, (state) => ({
    ...state,
    isDepartmentsLoading: true,
  })),
  on(NGPReportActions.getStoreDepartmentsSuccess, (state, {store, departments}) => ({
    ...state,
    isDepartmentsLoading: false,
    departments: {
      ...state.departments,
      [store.storeId]: departments,
    },
    error: null,
  })),
  on(NGPReportActions.getStoreDepartmentsFailure, (state, {error}) => ({
    ...state,
    isDepartmentsLoading: false,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsStoreDepartmentsLoading, (state, {isLoading}) => ({
    ...state,
    isDepartmentsLoading: isLoading,
  })),
  // ====================================================================================================
  // Get Store Suppliers
  // ====================================================================================================
  on(NGPReportActions.getStoreSuppliersByStoreId, (state) => ({
    ...state,
    isSuppliersLoading: true,
  })),
  on(NGPReportActions.getStoreSuppliersByStoreIdSuccess, (state, {suppliers, storeId}) => ({
    ...state,
    isSuppliersLoading: false,
    storeSuppliers: {
      ...state.storeSuppliers,
      [storeId]: suppliers,
    },
  })),
  on(NGPReportActions.getStoreSuppliersByStoreIdFailure, (state, {error}) => ({
    ...state,
    isSuppliersLoading: false,
    errors: [...state.errors, error],
  })),
  on(NGPReportActions.setIsStoreSuppliersByStoreIdLoading, (state, {isLoading}) => ({
    ...state,
    isSuppliersLoading: isLoading,
  })),
  // ====================================================================================================
  // Remove Selected Items From NGP Report
  // ====================================================================================================
  on(NGPReportActions.removeSelectedNgpReports, (state: NGPReportState) => {
    return {
      ...state,
      isNGPReportsLoading: true,
    };
  }),
  on(NGPReportActions.removeSelectedNgpReportsWithStoreID, (state: NGPReportState, {selectedReports, storeId}) => {
    const updatedNgpReports = {...state.ngpReports[storeId]};
    selectedReports.forEach((report: NGPReport) => {
      delete updatedNgpReports[report.stockId];
    });
    const updatedStockItems = state.stockItems[storeId].filter(
      (stockItem: StockItem) =>
        !selectedReports.some((report: NGPReport) => report.stockId === stockItem.stockId),
    );

    return {
      ...state,
      isNGPReportsLoading: false,
      ngpReports: {
        ...state.ngpReports,
        [storeId]: updatedNgpReports,
      },
      stockItems: {
        ...state.stockItems,
        [storeId]: updatedStockItems,
      },
    };
  }),
  // ====================================================================================================
  // Update Price, Nom Gp And GD Diff By StoreID
  // ====================================================================================================
  on(NGPReportActions.updatePriceGpForNgpReportWithStoreID, (state: NGPReportState, {ngpReport, field, storeId}) => {
    const nomGP = +StockFunctions.calcGP(storeId, ngpReport as unknown as StockItem, ngpReport.sellPriIncl1).toFixed(2);
    const copiedNgpReport = {...ngpReport};
    const oldNom = copiedNgpReport.nominalGP;
    const diffGP = nomGP === copiedNgpReport.nominalGP ? copiedNgpReport.diffGP : nomGP - oldNom;
    copiedNgpReport.prevCostPrice = +copiedNgpReport.originalValue['prevCostPrice'].value;

    switch (field) {
      case 'sellPriIncl1':
        copiedNgpReport.nominalGP = nomGP;
        copiedNgpReport.diffGP = diffGP;
        if (copiedNgpReport.nominalGP < 0.01) {
          copiedNgpReport.sellPriIncl1 = ReportUtils.calculatePriceFromGP(storeId, ngpReport);
          copiedNgpReport.nominalGP = +StockFunctions.calcGP(storeId, ngpReport as unknown as StockItem, ReportUtils.calculatePriceFromGP(storeId, ngpReport)).toFixed(2);
        }

        break;
      case 'nominalGP':
        const newPrice = ReportUtils.calculatePriceFromGP(storeId, ngpReport);
        copiedNgpReport.sellPriIncl1 = newPrice;
        const nomGP2 = +StockFunctions.calcGP(storeId, ngpReport as unknown as StockItem, newPrice).toFixed(2);
        copiedNgpReport.nominalGP = nomGP2;
        copiedNgpReport.diffGP = nomGP2 - +ngpReport.originalValue.nominalGP.value;

        break;
    }

    const updatedNgpReports = {
      ...state.ngpReports[storeId],
      [ngpReport.stockId]: {
        ...state.ngpReports[storeId][ngpReport.stockId],
        ...copiedNgpReport,
      },
    };
    const amountEdited = Object.values(updatedNgpReports).filter((report: NGPReport) => report['isEdited']).length;
    return {
      ...state,
      filtersAndTools: {
        ...state.filtersAndTools,
        editedCount: amountEdited,
      },
      ngpReports: {
        ...state.ngpReports,
        [storeId]: updatedNgpReports,
      },
    };
  }),
  on(NGPReportActions.getVatRateConversionSuccess, (state, {vatRates}) => ({
    ...state,
    vatRates,
  })),
  on(NGPReportActions.getVatRateConversionFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),

  on(NGPReportActions.setApplyPriceBandingToNGPReportItemsAtStoreId, (state, {ngpReports, store}) => ({
    ...state,
    ngpReports: {
      ...state.ngpReports,
      [store.storeId]: {
        ...state.ngpReports[store.storeId],
        ...ngpReports,
      },
    },
  })),
);

export const ngpReportReducer = (state: NGPReportState, action: Action): NGPReportState =>
  createCoreReducer(state, action);

// Todo: This all below needs to be moved to utils

export const addNewlyAddedItemsToNgpReports = (
  ngpReports: { [stockId: string]: NGPReport },
  newlyAddedItems: { [stockId: string]: NGPReport },
): { [stockId: string]: NGPReport } => {
  const ngpReportsAll = {...ngpReports};
  for (const stockId in newlyAddedItems) {
    ngpReportsAll[stockId] = {
      ...newlyAddedItems[stockId],
      newlyAdded: false,
    };
  }
  return ngpReportsAll;
};

export const getStockItemSuccessCalculations = (
  state: NGPReportState,
  store: IStore,
  stockItems: StockItem[],
  addOGValueField: boolean,
  existingReports?: NGPReport[],
): { filtersAndTools: FiltersAndTools; ngpReports: { [stockId: string]: NGPReport } } => {
  let ngpReportsArray: NGPReport[] = ReportUtils.convertStockItemsToNGPReports(stockItems || [], store.storeId);
  if (state.lineColours && ngpReportsArray.length > 0) {
    ngpReportsArray = GridUtils.applyLineColoursToNgpReports(
      state.lineColours[store.storeId],
      ngpReportsArray,
    );
  }
  let filtersAndTools: FiltersAndTools = state.filtersAndTools;
  if (ngpReportsArray.length > 0) {
    filtersAndTools = FilterAndToolUtils.determineFiltersAndTools(ngpReportsArray, filtersAndTools);
    filtersAndTools = FilterAndToolUtils.changeFilterAndToolsCounts(filtersAndTools, existingReports);
  }
  if (addOGValueField) {
    ngpReportsArray = addOriginalValueField(ngpReportsArray);
  }
  const ngpReports = ngpReportsArray.reduce((acc, ngpReport) => {
    acc[ngpReport.stockId] = ngpReport;
    return acc;
  }, {});
  return {ngpReports, filtersAndTools};
};

// Move this to utilities - future
// Change to generic type
export const addOriginalValueField = (ngpReports: NGPReport[]): NGPReport[] => {
  ngpReports.forEach((ngpReport: NGPReport): void => {
    ngpReport.originalValue = {};
    Object.keys(ngpReport).forEach((property: string): void => {
      if (property !== 'originalValue') {
        ngpReport.originalValue[property] = {value: ngpReport[property]};
      }
    });
    ngpReport.originalValue['newlyAdded'].value = false;
  });
  return ngpReports;
};
