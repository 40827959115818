import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {IStore} from '../../../shared/shared-models/store/store';

export function updateEditedItemsInLocalStorage(item: NGPReport, store: IStore, storageKey: string): void {
  try {
    let data = {};
    const serializedData = localStorage.getItem(storageKey);
    if (serializedData !== null) {
      data = JSON.parse(serializedData);
    }

    function hasChanges(report: NGPReport): boolean {
      for (const key in report) {
        if (key === 'originalValue' || key === 'isEdited' || key === 'isSelected' || key === '_tags') continue;

        const original = report.originalValue[key]?.value;
        if (report[key] !== original && report[key] !== data?.[store.storeId]?.[key]) {
          return true;
        }
      }
      return false;
    }

    if (hasChanges(item)) {
      if (!data[store.storeId]) {
        data[store.storeId] = {};
      }

      data[item.storeId][item.stockId] = item;

      const serializedReport = JSON.stringify(data);
      localStorage.setItem(storageKey, serializedReport);
    } else if (!hasChanges(item) && data?.[store.storeId]?.[item.stockId]) {
      delete data[store.storeId][item.stockId];
      if (Object.keys(data?.[store.storeId] as {}).length < 1) {
        delete data[store.storeId];
      }
      const serializedReport = JSON.stringify(data);
      localStorage.setItem(storageKey, serializedReport);
    }
  } catch (error: unknown) {
    console.error('Failed to save NGPReport to localStorage:', error);
  }
}

export function clearEditedItemsInLocalStorage(store: IStore, storageKey: string): void {
  const storeId = store.storeId;
  try {
    const serializedData = localStorage.getItem(storageKey);
    if (serializedData) {
      const savedEdits: { [storeId: string]: { [stockId: string]: NGPReport } } = JSON.parse(serializedData);
      if (savedEdits[storeId]) {
        delete savedEdits[storeId];
        if (Object.keys(savedEdits).length === 0) {
          localStorage.removeItem(storageKey);
        } else {
          localStorage.setItem(storageKey, JSON.stringify(savedEdits));
        }
      }
    }
  } catch (error: unknown) {
    console.error(`Error clearing ${storageKey} from localStorage:`, error);
  }
}
