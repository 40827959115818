import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {MenuController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {IUser} from './shared/shared-models/user-access/user';
import {INavigationPage, IPageObj, ISubPageObj} from './shared-utilities/models-old/page/page';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation,
} from './shared/shared-models/animations/animation';
import {InactivityService} from './shared-utilities/services-old/inactivity.service';
import {PAGES, PAGES_SUB} from './shared-utilities/models-old/page/pages-const';
import {Store} from '@ngrx/store';
import {
  getUser,
  getUserAccess,
  getUserMessageCount,
  setCurrentPageAndTab,
  setIsModuleLoading,
} from './features-as-modules/feature-core/store/core.actions';
import {FirebaseService} from './shared-utilities/services-old/firebase.service';
import {MessagesService} from './shared-utilities/services-old/message-service/messages.service';
import {initDepartmentFunctions} from './shared-utilities/functions-old/department-functions';
import {
  selectCurrentPageAndTabObject,
  selectIsModuleLoading,
  selectUser,
  selectUserAccess,
  selectUserMessageCount,
} from './features-as-modules/feature-core/store/core.selectors';
import {IUserAccess} from './shared/shared-models/user-access/user-access';
import {getDeepCopyOfObject} from './shared/shared-utils/object/object.utils';
import {
  CollectionOperationalAutoOrderingService,
} from './shared/shared-services/firebase/collection-operational-auto-ordering.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {getMessagesFromAppForAutoOrderUpdates} from "./features/core/store-shared/shared.actions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  user: IUser;

  currentNavigation$: Observable<{ currentSelectedPage: INavigationPage; }>;
  isModuleLoading$: Observable<boolean>;

  user$: Observable<IUser>;
  userAccess$: Observable<IUserAccess>;
  userMessageCount$: Observable<number>;

  menuPages: IPageObj[] = [];

  readonly menuPagesSubs: ISubPageObj = getDeepCopyOfObject(PAGES_SUB);
  readonly menuPagesSubsAll: ISubPageObj = getDeepCopyOfObject(PAGES_SUB);
  readonly animationLogo: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    id: 'app-component-animation-logo',
  };

  currentSelectedPageObj: INavigationPage = {} as INavigationPage;
  selectedPage: string[];
  private isNavigationInProgress = false;

  constructor(
    private titleService: Title,
    private firebaseService: FirebaseService,
    private router: Router,
    private messagesService: MessagesService,
    private menuControl: MenuController,
    private cdr: ChangeDetectorRef,
    private inactivityService: InactivityService,
    private collectionOperationalAutoOrderingService: CollectionOperationalAutoOrderingService,
    private readonly store: Store,
  ) {
    initDepartmentFunctions(firebaseService);
    this.inactivityService.startWatching();
  }

  get currentSelectedPage(): string[] {
    return [this.currentSelectedPageObj.currentPage, this.currentSelectedPageObj.currentTab];
  }

  ngOnInit(): void {
    this.setTitle();
    this.selectedPage = [this.currentSelectedPageObj.currentPage || 'home', this.currentSelectedPageObj.currentTab || null];
    this.menuPages = PAGES;
    this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: 'home', currentTab: null}}));
    this.router.events.subscribe((event: RouterEvent): void => {
      this.dispatchRouterEvents(event);
    });
    this.store.dispatch(getUser());
    this.store.dispatch(getUserAccess());
    this.store.dispatch(getUserMessageCount());

    this.user$ = this.store.select(selectUser);
    this.userAccess$ = this.store.select(selectUserAccess);

    this.userMessageCount$ = this.store.select(selectUserMessageCount);
    this.isModuleLoading$ = this.store.select(selectIsModuleLoading('home'));
    this.currentNavigation$ = this.store.select(selectCurrentPageAndTabObject);
    this.currentNavigation$.subscribe((navigation: { currentSelectedPage: INavigationPage; }): void => {
      this.setNavigation(navigation);
    });
    this.user$.subscribe((user: IUser) => {
      this.user = user;
    });
  }


  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  async onCloseMenu(): Promise<void> {
    await this.menuControl.close('main-menu');
  }

  changeMenuSize(value: string): void {
    if (value === 'expand') {
      document.querySelector('.side-nav').classList.add('expanded-nav');
    } else {
      document.querySelector('.side-nav').classList.remove('expanded-nav');
    }
  }

  getCurrentPageText(): string {
    const page = this.currentSelectedPage[0];
    const pageObj: IPageObj = this.menuPages.find((p: IPageObj): boolean => p.page === page);
    const subPageObj = this.menuPagesSubsAll['auto-ordering'].find((s: IPageObj): boolean => s.tab === this.currentSelectedPage[1]);
    if (subPageObj) {
      return pageObj ? `${pageObj.text} - ${subPageObj.text}` : '';
    } else {
      return pageObj ? pageObj.text : '';
    }
  }

  private setNavigation(selectedStorePageObj: { currentSelectedPage: INavigationPage }): void {
    this.currentSelectedPageObj = selectedStorePageObj.currentSelectedPage;
    this.selectedPage = [
      this.currentSelectedPageObj.currentPage,
      this.currentSelectedPageObj.currentTab,
    ];
    const tab = this.currentSelectedPageObj.currentTab;
    const queryParams = this.currentSelectedPageObj.currentTab ? {queryParams: {tab}} : {};
    this.router.navigate([this.currentSelectedPageObj.currentPage], queryParams)
      .then()
      .catch((error): void => {
          console.error(`Navigation failed to ${this.currentSelectedPageObj.currentPage}.`, error);
        },
      );
  }

  private dispatchRouterEvents(event: RouterEvent): void {
    if (event instanceof NavigationStart && !this.isNavigationInProgress) {
      this.isNavigationInProgress = true;
      this.store.dispatch(setIsModuleLoading({event, isLoading: true, instance: 'NavigationStart'}));
    }
    if (event instanceof NavigationEnd && this.isNavigationInProgress) {
      this.store.dispatch(setIsModuleLoading({event, isLoading: false, instance: 'NavigationEnd'}));
      this.isNavigationInProgress = false;
    }
  }

  private setTitle(): void {
    let title = this.titleService.getTitle();
    switch (environment.environment) {
      case 'Dev':
        title = `${title} - Dev`;
        break;
      case 'QA':
        title = `${title} - QA`;
        break;
      case 'Manage IT':
        title = `${title} - MI`;
        break;
      case 'Gallix':
      default:
        break;
    }
    this.titleService.setTitle(title);
  }

}
