import {IonAccordionGroup} from '@ionic/angular';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {IPageObj, ISubPageObj} from '../../../../shared-utilities/models-old/page/page';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {Router} from '@angular/router';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {Store} from '@ngrx/store';
import {setCurrentPageAndTab, setITA} from '../../store/core.actions';
import {AccessFlags, pageRules, RuleHumanID} from '../../../../shared-utilities/models-old/utils-old/rule-structure';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {IUserAccess} from '../../../../shared/shared-models/user-access/user-access';
import {PAGES, TECHO_ADMIN_PAGES} from '../../../../shared-utilities/models-old/page/pages-const';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @ViewChildren(IonAccordionGroup) accordionGroups: QueryList<IonAccordionGroup>;

  @Input() selectedPage?: string[] = [];
  @Input() defaultOpen?: boolean;
  @Input() subNav = false;

  @Output() readonly sizeChange: EventEmitter<string> = new EventEmitter<string>();

  pages: IPageObj[] = PAGES;
  userPages: IPageObj[] = [];
  subPages: ISubPageObj = {};
  clickCount = 0;
  icon: typeof Icons = Icons;
  showFullLogo = false;
  user: IUser;

  private menuExpanded = false;
  private menuFinishedExpanding = false;
  private menuCompactTimeout: NodeJS.Timeout;
  private userAccess: IUserAccess = {} as IUserAccess;

  constructor(
    private router: Router,
    private fireAuth: FireAuthService,
    private readonly store: Store,
    private cdr: ChangeDetectorRef,
  ) {
  }

  get isMenuExpanded(): boolean {
    return this.menuExpanded;
  }

  get hasMenuFinishedExpanding(): boolean {
    return this.menuFinishedExpanding;
  }

  @Input() set setSubPages(subPages: ISubPageObj) {
    subPages['auto-ordering'] = subPages['auto-ordering'].filter((page: IPageObj): boolean => page.tab !== 'unscheduled');
    this.subPages = subPages;
  };

  @Input() set setUser(user: IUser) {
    this.user = user;
    this.setPages();
  }

  @Input() set setUserAccess(userAccess: IUserAccess) {
    this.userAccessFlags(userAccess);
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.calledAfterInit();
  }

  increaseClickCount(): void {
    this.clickCount++;
  }

  calledAfterInit(): void {
    this.menuFinishedExpanding = this.menuExpanded;
    if (!this.menuExpanded) {
      this.onMouseLeftMenu();
    }
    this.menuFinishedExpanding = false;
    if (!this.selectedPage) {
      void this.router.navigate(['home']).then();
    }
  };

  onToggleAccordion(acc?: string): void {
    if (this.accordionGroups) {
      this.accordionGroups.forEach((accordionGroup: IonAccordionGroup): void => {
        const nativeElement: IonAccordionGroup = accordionGroup;
        nativeElement.value = nativeElement.value ? undefined : acc;
      });
    }
  }

  onNavigate(path: string, tab?: string): void {
    this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: path, currentTab: tab ? tab : null}}));
    this.selectedPage = [`${path}`, `${tab}`];
  }

  resetTimeOut(): void {
    if (this.menuCompactTimeout) {
      clearTimeout(this.menuCompactTimeout);
      this.menuCompactTimeout = null;
      this.menuFinishedExpanding = this.menuExpanded;
    }
  }

  onMouseOverMenu(): void {
    if (this.isMenuExpanded) {
      this.menuFinishedExpanding = true;
    }
  }

  onMouseLeftMenu(): void {
    if (!this.menuCompactTimeout) {
      this.menuCompactTimeout = setTimeout((): void => {
        if (this.menuExpanded) {
          this.onToggleMenu();
        }
      }, 1000);
    }
  }

  onToggleMenu(): void {
    this.clickCount = 0;
    this.menuExpanded = !this.menuExpanded;
    this.onToggleAccordion();
    setTimeout((): void => {
      this.menuFinishedExpanding = this.menuExpanded;
    }, 500);
    if (!this.showFullLogo) {
      this.showFullLogo = !this.showFullLogo;
    } else {
      setTimeout((): void => {
        this.showFullLogo = !this.showFullLogo;
      }, 450);
    }

    this.emitSizeChange();
  }

  expandMenuOnAccClick(acc: string): void {
    if (!this.isMenuExpanded) {
      this.onToggleMenu();
      this.onToggleAccordion(acc);
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  private setPages(): void {
    // Todo: refactor at some point
    this.cdr.detectChanges();
    if (this.userAccess && this.user?.id) {
      this.userPages = this.pages?.filter((po: IPageObj): boolean => {
        const rules = pageRules(po.page);
        return rules
          ? rules.some((rule: RuleHumanID | AccessFlags) =>
            this.userAccess?.storeList?.some((storeId: string) =>
              this.fireAuth.hasAccess(storeId, {ruleID: rule})))
          : false;
      });
      if (!this.subNav) {
        this.userPages.unshift({page: 'home', text: 'Home', icon: Icons.home});
      }
    } else {
      this.userPages = [this.pages.find((po: IPageObj) => po.page === 'login')];
    }
    if (this.userAccess?.isTechoAdmin) {
      this.userPages = this.userPages.concat(TECHO_ADMIN_PAGES);
    }
  }

  private emitSizeChange(): void {
    this.sizeChange.emit(this.menuExpanded ? 'expand' : 'contract');
  }

  private userAccessFlags(userAccess: IUserAccess): void {
    this.userAccess = {} as IUserAccess;

    this.userAccess = userAccess;
    this.store.dispatch(setITA({ita: userAccess?.['isTechoAdmin']}));
    this.setPages();
  }

}
