import {createAction, props} from '@ngrx/store';
import {ISharedModalBasic} from '../../../shared/shared-models/modals/shared-modal-basic';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IGroupAccess, IGroupAccessPerStore} from '../../../shared/shared-models/user-access/group-access';

export const sendInviteLink = createAction(
  '[User Access][Invite Link] Send Invite Link',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore }>(),
);

export const sendInviteLinkSuccess = createAction(
  '[User Access][Invite Link] Send Invite Link Success',
  props<{ userId: string }>(),
);

export const sendInviteLinkFailure = createAction(
  '[User Access][Invite Link] Send Invite Link Failure',
  props<{ error: IError }>(),
);

export const setPermissionsFailure = createAction(
  '[User Access] Set Permissions Failure',
  props<{ error: IError }>(),
);

export const setUserAccessLoading = createAction(
  '[User Access] Set User Access Loading',
  props<{ isLoading: boolean }>(),
);

export const showUserAccessModal = createAction(
  '[User Access] Show User Access Modal',
  props<{ modalProps: ISharedModalBasic, editedPermissions?: IGroupAccessPerStore, userId?: string }>(),
);

export const setColleaguesUserAccess = createAction(
  '[User Access] Set Colleagues User Access',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore, merge: boolean, newUser: boolean }>(),
);

export const setColleaguesUserAccessSuccess = createAction(
  '[User Access] Set Colleagues User Access Success',
);

export const setColleaguesUserAccessFailure = createAction(
  '[User Access] Set Colleagues User Access Failure',
  props<{ error: IError }>(),
);

export const getGroupAccessSettings = createAction(
  '[User Access] Get Group Access Settings',
);

export const getGroupAccessSettingsSuccess = createAction(
  '[User Access] Get Group Access Settings Success',
  props<{ groupAccess: IGroupAccess }>(),
);

export const getGroupAccessSettingsFailure = createAction(
  '[User Access] Get Group Access Settings Failure',
  props<{ error: IError }>(),
);


export const setColleaguesUserAccessForNonAccessibleUser = createAction(
  '[User Access] Set Colleagues User Access For Non Accessible User',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore }>(),
);

export const setColleaguesUserAccessForNonAccessibleUserSuccess = createAction(
  '[User Access] Set Colleagues User Access For Non Accessible User Success',
);

export const setColleaguesUserAccessForNonAccessibleUserFailure = createAction(
  '[User Access] Set Colleagues User Access For Non Accessible User Failure',
  props<{ error: IError }>(),
);

export const setNewServerAdminUser = createAction(
  '[User Access][Server] Set New Server Admin User',
  props<{ userId: string, editedPermissions: IGroupAccessPerStore }>(),
);

export const setNewServerAdminUserSuccess = createAction(
  '[User Access][Server] Set New Server Admin User Success',
  props<{ userId: string }>(),
);

export const setNewServerAdminUserFailure = createAction(
  '[User Access][Server] Set New Server Admin User Failure',
  props<{ error: IError }>(),
);
