import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {
  CollectionUserAccessColleaguesService,
} from '../../../shared/shared-services/firebase/collection-user-access-colleagues.service';
import {ModalController} from '@ionic/angular';
import {
  SharedModalBasicComponent,
} from '../../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import * as UserAccessActions from './user-access.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, from, of} from 'rxjs';
import {ISharedModalBasic} from '../../../shared/shared-models/modals/shared-modal-basic';
import {selectUser} from '../../../features-as-modules/feature-core/store/core.selectors';
import {select, Store} from '@ngrx/store';
import {Icons} from '../../../shared-modules/shared-icons/icons';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {IGroupAccess} from '../../../shared/shared-models/user-access/group-access';
import {selectColleagues} from '../../core/store-shared/shared.selectors';
import {OverlayEventDetail} from '@ionic/core';
import {IColleagueUser} from '../../../shared/shared-models/user-access/user-access';

@Injectable()
export class UserAccessEffects {


//[User Access] Show Modal
  showModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAccessActions.showUserAccessModal),
      mergeMap((action) =>
        from(this.modalController.create({
          component: SharedModalBasicComponent,
          componentProps: action.modalProps,
          cssClass: ['shared-basic-modal-css-medium'],
        })).pipe(
          mergeMap((modalController: HTMLIonModalElement) =>
            from(modalController.present()).pipe(
              mergeMap(() =>
                from(modalController.onDidDismiss()).pipe(
                  mergeMap((response: OverlayEventDetail) => {
                    if (action.modalProps.modalTitle === 'User Already Exists.' && response?.data?.buttonPressed === 'button-accept') {
                      return of(UserAccessActions.setColleaguesUserAccessForNonAccessibleUser({
                        userId: action.userId,
                        editedPermissions: action.editedPermissions,
                      }));
                    }
                    return EMPTY;
                  }),
                ),
              ),
            ),
          ),
          catchError((error: IError) => {
            console.error(error);
            return EMPTY;
          }),
        ),
      ),
    ),
  );


  //[User Access] set Colleagues User Access
  setColleaguesUserAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAccessActions.setColleaguesUserAccess),
      withLatestFrom(this.store.pipe(select(selectColleagues))),
      mergeMap(([{userId, editedPermissions, merge, newUser}, colleagues]) =>
        from(
          this.collectionUserAccessColleaguesService.setColleagues(
            userId,
            editedPermissions,
            colleagues[userId],
            merge,
            newUser,
          ),
        ).pipe(
          map(() => UserAccessActions.setColleaguesUserAccessSuccess()),
          catchError((error: IError) =>
            of(UserAccessActions.setColleaguesUserAccessFailure({error})),
          ),
        ),
      ),
    ),
  );

  //[User Access] set Colleagues User Access
  setColleaguesUserAccessForNonAccessibleUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAccessActions.setColleaguesUserAccessForNonAccessibleUser),
      mergeMap(({userId, editedPermissions}) =>
        from(
          this.collectionUserAccessColleaguesService.setNonAccessibleColleague(
            userId,
            editedPermissions,
          ),
        ).pipe(
          map(() => UserAccessActions.setColleaguesUserAccessForNonAccessibleUserSuccess()),
          catchError((error: IError) =>
            of(UserAccessActions.setColleaguesUserAccessForNonAccessibleUserFailure({error})),
          ),
        ),
      ),
    ),
  );


//[User Access] Get Group Access Settings
  loadGroupAccessSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAccessActions.getGroupAccessSettings),
      mergeMap(() =>
        from(this.firebaseService.getGroupAccessSettings()).pipe(
          map((result: IGroupAccess) =>
            UserAccessActions.getGroupAccessSettingsSuccess({groupAccess: result}),
          ),
          catchError((error: IError) =>
            of(UserAccessActions.getGroupAccessSettingsFailure({error})),
          ),
        ),
      ),
    ),
  );


  protected readonly icons = Icons;
  //'[User Access] Resend Invite Link',
  resendInviteLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAccessActions.sendInviteLink),
      withLatestFrom(
        this.store.pipe(select(selectUser)),
        this.store.pipe(select(selectColleagues)),
      ),
      mergeMap(([{userId, editedPermissions}, {id}, colleagues]) => {
        const addUserHttpFunction = this.angularFireFunctions.httpsCallable('createUserWithWelcomeEmail');

        return from(
          addUserHttpFunction({
            newUserEmail: userId,
            requesterEmail: id,
            addedStoresWithGroup: editedPermissions,
          }).toPromise(),
        ).pipe(
          mergeMap((response: { success: boolean, message: string[] }) => {
            if (response.success) {
              return from(
                this.collectionUserAccessColleaguesService.setColleagues(
                  userId,
                  editedPermissions,
                  colleagues[userId],
                  false,
                  true,
                  true,
                ),
              ).pipe(
                mergeMap(() => {
                  const successModalProps: ISharedModalBasic = {
                    buttonAccept: false,
                    buttonAcceptText: '',
                    buttonClose: true,
                    buttonCloseCross: false,
                    buttonCloseText: 'Continue',
                    modalTitle: 'Sign up Email Sent!',
                    modalTitleIcon: this.icons.person,
                    modalTitleIconColor: 'green-stroke',
                    contextHeading: 'The Email has been sent.',
                    contextDescription: ['Please remember that the email will expire in an hour.'],
                  };
                  return [
                    UserAccessActions.showUserAccessModal({modalProps: successModalProps}),
                    UserAccessActions.sendInviteLinkSuccess({userId: userId}),
                  ];
                }),
                catchError((error: IError) => {
                  const errorModalProps: ISharedModalBasic = {
                    buttonAccept: false,
                    buttonAcceptText: '',
                    buttonClose: true,
                    buttonCloseCross: false,
                    buttonCloseText: 'Continue',
                    modalTitle: 'Error: Email sent but permissions not updated.',
                    modalTitleIcon: this.icons.bug,
                    modalTitleIconColor: 'red',
                    contextHeading: 'An unexpected error occurred.',
                    contextDescription: [
                      'We were unable to update the permissions for this user.',
                      'Please click on the user name and update the permissions manually.',
                    ],
                  };
                  console.error('Error writing the user to user_access collection: ', error);
                  return [
                    UserAccessActions.showUserAccessModal({modalProps: errorModalProps}),
                    UserAccessActions.setPermissionsFailure({error}),
                  ];
                }),
              );
            } else {
              const userExistsModalProps: ISharedModalBasic = {
                buttonAccept: true,
                buttonAcceptText: 'Add User To Stores',
                buttonClose: true,
                buttonCloseCross: false,
                buttonCloseText: 'Cancel',
                modalTitle: 'User Already Exists.',
                modalTitleIcon: this.icons.person,
                modalTitleIconColor: 'yellow-color',
                contextHeading: 'This email is already associated with an existing account.',
                contextDescription: [
                  'If you do not have access to this user, please click add user to stores.',
                  'If you have access, you can update their permissions directly from their user profile.',
                ],
              };
              const error: IError = {error: response.message} as IError;
              return [
                UserAccessActions.showUserAccessModal({modalProps: userExistsModalProps, editedPermissions, userId}),
                UserAccessActions.sendInviteLinkFailure({error}),
              ];
            }
          }),
          catchError((error: IError) => [
            UserAccessActions.sendInviteLinkFailure({error}),
          ]),
        );
      }),
    ),
  );


  setNewServerAdminUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAccessActions.setNewServerAdminUser),
      withLatestFrom(
        this.store.pipe(select(selectUser)),
      ),
      mergeMap(([{userId, editedPermissions}, {id}]) => {
        const addUserHttpFunction = this.angularFireFunctions.httpsCallable('createUserWithWelcomeEmail');
        return from(
          addUserHttpFunction({
            newUserEmail: userId,
            requesterEmail: id,
            addedStoresWithGroup: editedPermissions,
          }),
        ).pipe(
          mergeMap((response: { success: boolean, message: string[] }) => {
            if (response.success) {
              return from(
                this.collectionUserAccessColleaguesService.setColleagues(
                  userId,
                  editedPermissions,
                  {} as IColleagueUser,
                  false,
                  true,
                ),
              ).pipe(
                mergeMap(() => {
                  const successModalProps: ISharedModalBasic = {
                    buttonAccept: false,
                    buttonAcceptText: '',
                    buttonClose: true,
                    buttonCloseCross: false,
                    buttonCloseText: 'Continue',
                    modalTitle: 'Sign up Email Sent!',
                    modalTitleIcon: this.icons.person,
                    modalTitleIconColor: 'green-stroke',
                    contextHeading: 'The Email has been sent.',
                    contextDescription: ['Please remember that the email will expire in an hour.'],
                  };
                  return [
                    UserAccessActions.showUserAccessModal({modalProps: successModalProps}),
                    UserAccessActions.setNewServerAdminUserSuccess({userId: userId}),
                  ];
                }),
                catchError((error: IError) => {
                  const errorModalProps: ISharedModalBasic = {
                    buttonAccept: false,
                    buttonAcceptText: '',
                    buttonClose: true,
                    buttonCloseCross: false,
                    buttonCloseText: 'Continue',
                    modalTitle: 'Error: Email sent but permissions not updated.',
                    modalTitleIcon: this.icons.bug,
                    modalTitleIconColor: 'red',
                    contextHeading: 'An unexpected error occurred.',
                    contextDescription: [
                      'We were unable to update the permissions for this user.',
                      'Please click on the user name and update the permissions manually.',
                    ],
                  };
                  console.error('Error writing the user to user_access collection: ', error);
                  return [
                    UserAccessActions.showUserAccessModal({modalProps: errorModalProps}),
                    UserAccessActions.setNewServerAdminUserFailure({error}),
                  ];
                }),
              );
            } else {
              const userExistsModalProps: ISharedModalBasic = {
                buttonAccept: true,
                buttonAcceptText: 'Add User To Stores',
                buttonClose: false,
                buttonCloseCross: false,
                buttonCloseText: 'Cancel',
                modalTitle: 'User Already Exists.',
                modalTitleIcon: this.icons.person,
                modalTitleIconColor: 'yellow-color',
                contextHeading: 'This email is already associated with an existing account.',
                contextDescription: [
                  'Please Click Add User To Stores Button To Update The Users Permissions',
                ],
              };
              const error: IError = {error: response.message} as IError;
              return [
                UserAccessActions.showUserAccessModal({modalProps: userExistsModalProps, editedPermissions, userId}),
                UserAccessActions.setNewServerAdminUserFailure({error}),
              ];
            }
          }),
          catchError((error: IError) => [
            UserAccessActions.sendInviteLinkFailure({error}),
          ]),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private angularFireFunctions: AngularFireFunctions,
    private collectionUserAccessColleaguesService: CollectionUserAccessColleaguesService,
    private modalController: ModalController,
    private firebaseService: FirebaseService,
    private readonly store: Store,
  ) {
  }
}
