import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {IFirebaseQuery} from "../../shared-models/firebase/firebase-queries";
import {Observable} from "rxjs";
import {path_operational_stores_data_storeId_messages_from_app} from "../database-paths";
import {IStore} from "../../shared-models/store/store";
import {generateFirebaseCollectionReference} from '../../shared-utils/firestore/firestore.utils';
import {IMessage} from "../../../features/core/models/message";

@Injectable({
  providedIn: 'root',
})
export class CollectionOperationalMessagesServiceService {

  constructor(private angularFirestore: AngularFirestore) {
  }

  getMessagesUsingQuery<DataType>(path: string, firebaseQueries: IFirebaseQuery[]): Observable<IMessage<DataType>[]> {
    const collection = this.angularFirestore
      .collection(path);
    let queryRef = collection.ref;
    queryRef = generateFirebaseCollectionReference(firebaseQueries, queryRef);

    return new Observable((observer) => {
      queryRef.onSnapshot((snapshot) => {
        const messages: IMessage<DataType>[] = [];
        snapshot.forEach((doc) => {
          const docMessage = doc.data() as IMessage<DataType>;
          messages.push(docMessage);
        });
        observer.next(messages);
      }, (error) => {
        observer.error(error);
      });
    });
  }


}
