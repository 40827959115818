import {ModalController, PopoverController} from '@ionic/angular';
import {
  SaveUserSettingsPage,
} from '../../shared-modules/shared-module/modules-old/save-user-settings/save-user-settings.page';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  SelectPopoverComponent,
} from '../../shared-modules/shared-module/components/select-popover/select-popover.component';

export interface SaveOptions {
  tableId: string;
  modalController: ModalController;
  windowTitle?: string; // = 'Field Layout'
  specialStates?: { [col: string]: any }; // = null;
  idPrefix?: string; // = 'col-';
  onlyOne?: 'save' | 'load'; // = null;
  saveDocument?: string; // = this.saveDocument
}

export const checkSaveOptions = (opts: SaveOptions) => {
  if (!opts.tableId || !opts.modalController) {
    throw Error('SaveOptions needs both tableID and modalController');
  }
};

export class TableDrag {

  boxShadow: string;

  shadowColour = 'GOLD';
  private dragged: string;
  private displayedColumns: string[];

  private readonly saveDocument: string;
  private readonly hardCoded: string[] = [];
  private readonly selectable: { [key: string]: string };

  private readonly columnDropEvtBS: BehaviorSubject<string[]>;

  constructor(columns: string[], saveDocument?: string, hardCoded?: string[], selectable?: { [key: string]: string },
              shadowColour?: string, boxShadow?: string) {
    this.displayedColumns = columns;

    if (saveDocument) {
      this.saveDocument = saveDocument;
    }
    if (hardCoded) {
      this.hardCoded = hardCoded;
    }
    if (selectable) {
      this.selectable = selectable;
    }
    if (shadowColour) {
      this.shadowColour = shadowColour;
    }
    if (boxShadow) {
      this.boxShadow = boxShadow;
    }
    this.columnDropEvtBS = new BehaviorSubject(columns ? columns : null);
  }

  get hardCodedColumns(): string[] {
    return this.hardCoded;
  }

  get displayable(): string[] {
    if (this.selectable) {
      return Object.keys(this.selectable).sort();
    }
    return null;
  }

  get columnsWTitles(): [string, string][] {
    return this.displayedColumns.map((key) => [key, this.title(key)]);
  }

  get columnDrops(): Observable<string[]> {
    return this.columnDropEvtBS.asObservable();
  }

  get columns(): string[] {
    return this.displayedColumns;
  }

  set columns(columns: string[]) {
    this.displayedColumns = columns;
    this.columnDropEvtBS.next(columns);
  }

  dragStart(column: string, event) {
    event.target.style.opacity = 0.5;
    this.dragged = column;
  }

  dragEnd(event) {
    event.target.style.opacity = 1;
  }

  dragEnter(event) {
    if ('' + event.target !== '[object Text]') {
      if (this.boxShadow) {
        event.target.style.boxShadow = this.boxShadow;
      } else {
        event.target.style.boxShadow = `inset 10px 0px 0px 0px ${this.shadowColour}`;
      }
    }
  }

  dragLeave(event) {
    if ('' + event.target !== '[object Text]') {
      event.target.style.boxShadow = null;
    }
  }

  async drop(bottomColumn: string, event) {
    event.target.style.boxShadow = null;

    if (bottomColumn !== this.dragged) {
      this.displayedColumns.splice(this.displayedColumns.indexOf(this.dragged), 1);
      const idx = this.displayedColumns.indexOf(bottomColumn);
      const nd = this.displayedColumns.slice(0, idx).concat(this.dragged).concat(this.displayedColumns.slice(idx));
      this.displayedColumns = nd;
      this.columnDropEvtBS.next(this.displayedColumns);
    }
  }

  title(key: string) {
    return this.selectable[key];
  }

  selectVisible(popControl: PopoverController, defaultSelection?: string[], disabled?: string[],
                saveOptions?: SaveOptions, event?) {
    if (!this.selectable) {
      throw Error('TableDrag was initialised without selectable parameter. This parameter is required to use select ' +
        'visible');
    }

    if (saveOptions) {
      checkSaveOptions(saveOptions);
    }
    return new Promise<any>(async (resolve, reject) => {
      let saveLoadButton;
      let defaultButton;

      if (saveOptions) {
        saveLoadButton = {
          text: 'Save/Load', handler: () => {
            this.saveNLoad(saveOptions)
              .then((special) => {
                resolve(special);
              });
          },
        };
      }

      if (defaultSelection) {
        defaultButton = {
          text: 'Default', handler: (checks: { [sKey: string]: boolean }, dis: string[]) => {
            for (const sKey of Object.keys(this.selectable)) {
              if (!dis || !dis.includes(sKey)) {
                checks[sKey] = defaultSelection.includes(sKey);
              }
            }
            return checks;
          },
        };
      }

      const pc = await popControl.create({
        component: SelectPopoverComponent, event, componentProps: {
          title: 'Select Visible Fields',
          selection: this.selectable, disabled,
          order: Object.keys(this.selectable).sort((a, b) => this.selectable[a] < this.selectable[b] ? -1 : 1),
          value: this.columns,
          multiple: true, selectAll: true,
          selectModButtons: [defaultButton],
          additionalMainButton: saveLoadButton,
        },
      });
      await pc.present();
      const {data} = await pc.onDidDismiss();

      if (data) {
        if (data.length > 0) {
          this.columns = data;
        } else {
          this.columns = defaultSelection ? defaultButton : [];
        }
      }
      resolve(data);
    });
  }

  saveNLoad(opts: SaveOptions): Promise<{ [col: string]: any } | void> {
    checkSaveOptions(opts);
    const tableID: string = opts.tableId;
    const modalController: ModalController = opts.modalController;
    const windowTitle: string = opts.windowTitle ? opts.windowTitle : 'Field Layout';
    const specialStates: { [col: string]: any } = opts.specialStates ? opts.specialStates : null;
    const idPrefix: string = opts.idPrefix ? opts.idPrefix : 'col-';
    const onlyOne: 'save' | 'load' = opts.onlyOne ? opts.onlyOne : null;
    const saveDocument: string = opts.saveDocument ? opts.saveDocument : this.saveDocument;

    if (!saveDocument) {
      throw Error(`Save Location not set for table. tableID: ${tableID}, idPrefix: ${idPrefix}`);
    }
    let getSaveObj: () => { order: string[]; details: { [col: string]: { width: number; specialStatus?: any } } };

    if (!onlyOne || onlyOne === 'save') {
      getSaveObj = () => {
        const table = document.getElementById(tableID);
        const headers = table.getElementsByTagName('th');

        let tableWidth = table.offsetWidth;
        const sizes: { [col: string]: number } = {};
        const order: string[] = [];

        for (let i = 0; i < headers.length; i++) {
          const header = headers.item(i);

          if (header.id && header.id.startsWith(idPrefix)) {
            const col = header.id.substring(idPrefix.length, header.id.length);
            sizes[col] = header.offsetWidth;
            order.push(col);
          } else {
            tableWidth -= header.offsetWidth;
          }
        }
        const saveObj = {order, details: {}};

        for (const col of order) {
          saveObj.details[col] = {width: +((sizes[col] / tableWidth) * 100).toFixed(1)};

          if (this.hardCoded.includes(col)) {
            saveObj.details[col].hard = true;
          }

          if (specialStates && specialStates[col]) {
            saveObj.details[col].specialStatus = specialStates[col];
          }
        }
        return saveObj;
      };
    }

    return new Promise<{ [p: string]: any } | void>(resolve => {
      modalController.create({
        component: SaveUserSettingsPage, componentProps: {
          getSaveObj,
          document: 'visible-fields',
          what: windowTitle,
          entrySuffix: 'auto-ordering',
          entryPrefix: 'open-orders',
          order: getSaveObj().order,
        },
      }).then(mc => mc.present().then(() => mc.onDidDismiss().then(result => {
        if (result.data) {
          const data = result.data.data as {
            order: string[]; details: { [col: string]: { width: number; specialStatus?: any } };
          };
          const sizes = new Map<string, number>();
          const specials: { [col: string]: any } = {};


          for (const col of data.order) {
            sizes.set(col, data.details[col].width);
            if (data.details[col].specialStatus) {
              specials[col] = data.details[col].specialStatus;
            }
          }

          if (Object.keys(specials).length) {
            resolve(specials);
          } else {
            resolve();
          }
          this.layoutOnRender(tableID, idPrefix, sizes);
        } else {
          resolve();
        }
      })));
    });
  }

  layoutOnRender(tableID: string, idPrefix: string, sizes: Map<string, number>) {
    const columnList = [];

    for (const col of sizes.keys()) {
      columnList.push(col);
    }
    this.displayedColumns = columnList.filter((col) => !this.hardCoded.includes(col));
    const resize = () => {
      let d = new Date();
      setTimeout(() => {
        d = new Date();
        const table = document.getElementById(tableID) as HTMLTableElement;

        if (table) {
          const headers = table.getElementsByTagName('th');

          if (headers && headers.length >= sizes.size) {
            let finished = null;

            for (const col of columnList) {
              const id = idPrefix + col;
              let i: number;

              for (i = 0; i < headers.length; i++) {
                if (headers.item(i).id.includes(id)) {
                  headers.item(i).style.width = `${sizes.get(col)}%`;
                  finished = col;
                  break;
                }
              }

              if (i === headers.length) {
                break;
              }
            }

            if (finished === columnList[columnList.length - 1]) {
              return;
            }
          }
        }
        resize();
      }, 1000);
    };
    resize();
  }
}
