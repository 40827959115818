import {Component, Input} from '@angular/core';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {ModalController, PopoverController} from '@ionic/angular';
import {ISharedModalInputOne, ISharedModalInputOneResponse} from '../../../shared-models/modals/shared-modal-input-one';

@Component({
  selector: 'app-shared-modal-input-one',
  templateUrl: './shared-modal-input-one.component.html',
  styleUrls: ['./shared-modal-input-one.component.scss'],
})
export class SharedModalInputOneComponent implements ISharedModalInputOne {

  icons: typeof Icons = Icons;

  @Input() buttonAccept: boolean;
  @Input() buttonAcceptText: string;
  @Input() buttonClose: boolean;
  @Input() buttonCloseCross: boolean;
  @Input() buttonCloseText: string;
  @Input() buttonSecondary: boolean;
  @Input() buttonSecondaryText: string;
  @Input() inputHeading: string;
  @Input() inputPlaceholder: string;
  @Input() inputText: string | number | Date;
  @Input() inputType: 'string' | 'number' | 'Date';
  @Input() modalTitle: string;
  @Input() modalTitleIcon: Icons;
  @Input() modalTitleIconColor: 'green' | 'red' | 'yellow' | 'white' = 'white';
  @Input() contextHeading: string;
  @Input() contextDescription: string[];

  constructor(
    private modalController: ModalController
  ) {
  }

  @Input() set popoverReference(popoverController: PopoverController) {
    void popoverController.dismiss().then();
  };

  onButtonPress(buttonPressed: 'button-accept' | 'button-secondary' | 'button-close'): void {
    void this.modalController.dismiss(
      {buttonPressed, inputText: this.inputText} as ISharedModalInputOneResponse,
      buttonPressed === 'button-accept' ? 'modal-accept' : 'modal-close',
    ).then((returned: boolean) => returned);
  }

  trackByFunction(index: number): number {
    return index;
  }
}
