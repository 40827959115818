import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {IonNav, IonRouterOutlet, ModalController} from '@ionic/angular';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {NavigableModalComponent} from '../navigable-modal/navigable-modal.component';
import {DisableSupplierSettingsComponent} from '../disable-supplier-dettings/disable-supplier-settings.component';
import {AutomationSettingsEmailComponent} from '../automation-settings-email/automation-settings-email.component';
import {StoreInfoComponent} from '../store-info/store-info.component';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {RuleHumanID} from '../../../../shared-utilities/models-old/utils-old/rule-structure';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {Store} from '@ngrx/store';
import {getUserStores, setUserSelectedStore} from '../../../../features-as-modules/feature-core/store/core.actions';
import {IStore} from '../../../../shared/shared-models/store/store';
import {selectUserStores} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {
  SettingsGeneralGridContainerComponent,
} from '../../../../features/settings/components/settings-store/settings-general-grid-container/settings-general-grid-container.component';
import {getColleagues, killColleaguesService} from '../../../../features/core/store-shared/shared.actions';
import {StoreInfo} from '../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-store-config',
  templateUrl: './store-config.component.html',
  styleUrls: ['./store-config.component.scss'],
})
export class StoreConfigComponent implements OnInit, OnDestroy {

  selectedStoreBS: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  changes: { [document: string]: any };
  disabled: { [storeId: string]: boolean } = {};

  userStores$: Observable<IStore[]>;
  selectedStore: IStore;

  private modalButtons: { [storeId: string]: { text: string; component: any; rules: (RuleHumanID)[] }[] } = {};
  private readonly modalButtonsAll: { text: string; component: any; rules: (RuleHumanID)[] }[] = [
    {text: 'Store Information (Temporary)', component: StoreInfoComponent, rules: ['d.', 'd.iv', 'd.vi']},
    {
      text: 'Define suppliers disabling rules',
      component: DisableSupplierSettingsComponent,
      rules: ['a.'],
    },
    {text: 'General Stock Settings', component: SettingsGeneralGridContainerComponent, rules: ['a.']},
    //{text: 'Configure Stock Manger settings', component: SettingsStockManagerComponent, rules: ['b.']},
    {
      text: 'Edit Email Settings',
      component: AutomationSettingsEmailComponent,
      rules: ['d.', 'd.iv', 'd.vi'],
    },
  ];

  private subs: Subscription[] = [];

  constructor(
    private firebase: FirebaseService,
    private fireAuth: FireAuthService,
    private modalController: ModalController,
    private nav: IonNav,
    private readonly store: Store,
    @Optional() private routerOutlet: IonRouterOutlet,
  ) {
  }

  get buttons(): { text: string; component: any; rules: (RuleHumanID)[] }[] {
    return this.modalButtons[this.selectedStore.storeId];
  }

  ngOnInit() {
    this.store.dispatch(getUserStores({pageStoreDocument: 'messages-modal'}));
    this.store.dispatch(getColleagues());
    this.subs.push(this.fireAuth.userAccess.subscribe((ua) => {
      this.modalButtons = {};
      ua.storeList.forEach((storeId) => {
        this.modalButtons[storeId] = [];

        for (const mb of this.modalButtonsAll) {
          let access = true;

          for (const hID of mb.rules) {
            if (this.fireAuth.hasAccess(storeId, {ruleID: hID}) !== true) {
              access = false;
              break;
            }
          }

          if (access) {
            this.modalButtons[storeId].push(mb);
          }
        }
      });
    }));
    this.userStores$ = this.store.select(selectUserStores);

    // setTimeout(() => this.open('app-automation-settings-email').then(), 1000);
  }

  ngOnDestroy() {
    for (const subscription of this.subs) {
      subscription.unsubscribe();
    }
    this.store.dispatch(killColleaguesService());
  }

  onStoreChange(selectedStore: IStore): void {
    this.selectedStore = selectedStore;
    // Todo: Move this to an effect.
    this.store.dispatch(setUserSelectedStore({selectedStore}));
  }


  // ok(storeId: string): boolean {
  //   return this.fireAuth.hasAccess(storeId, {ruleID: 'b.ii'}) === true &&
  //     this.fireAuth.hasAccess(storeId, {ruleID: 'b.'}) === true;
  // }

  close = () => {
    this.nav.pop().then();
  };

  async open(component: any) {
    const storeId = this.selectedStore.storeId;
    const storeInfo = {name: this.selectedStore.name} as unknown as StoreInfo;


    const mc = await this.modalController.create({
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      component: NavigableModalComponent,
      componentProps: {root: component, rootParams: {storeId, storeInfo, cssClass: 'child-modal'}},
      cssClass: 'larger-modal',
      backdropDismiss: false,
      // component, componentProps: {storeId, storeInfo}, cssClass: 'child-modal'
    });
    await mc.present();
    const {data} = await mc.onDidDismiss();
  }

  storeSelect = (storeId: string): boolean => true;

}
