import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {deleteStockItemTags, setStockItemTags} from '../../../store/settings.actions';

@Component({
  selector: 'app-settings-stock-tags',
  templateUrl: './settings-stock-tags.component.html',
  styleUrls: ['./settings-stock-tags.component.scss'],
})
export class SettingsStockTagsComponent {
  newTag: string = '';
  isTagInvalid: boolean = false;
  tagErrorMessage: string = '';
  tags: { name: string; global: boolean }[] = [];

  constructor(private readonly store: Store) {
  }

  @Input() set setTags(allTags: { name: string; global: boolean }[]) {
    this.tags = allTags;
  }

  formatTag(): void {
    this.newTag = this.newTag.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');

    if (this.newTag.length === 0) {
      this.tagErrorMessage = 'Tag cannot be empty.';
    } else if (this.newTag.length > 15) {
      this.tagErrorMessage = 'Tag cannot exceed 15 characters.';
    } else if (this.tags.some((tag: {
      name: string,
      global: boolean
    }) => tag.name.toLowerCase() === this.newTag.toLowerCase())) {
      this.tagErrorMessage = 'Tag already exists.';
    } else {
      this.tagErrorMessage = '';
    }

    this.isTagInvalid = !!this.tagErrorMessage;
  }

  addTag(): void {
    if (!this.newTag || this.isTagInvalid) return;

    this.store.dispatch(setStockItemTags({tag: this.newTag}));
    this.newTag = '';
    this.isTagInvalid = false;
    this.tagErrorMessage = '';
  }

  deleteTag(tagName: string): void {
    this.store.dispatch(deleteStockItemTags({tag: tagName}));
  }

  trackByFunction(index: number): number {
    return index;
  }
}
