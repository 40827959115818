import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class CollectionGlobalService {
  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  async setDocument<DataType>(
    path: string,
    data: DataType,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .set(data, {merge: true});
    } catch (error) {
      console.error(error);
    }
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.exists) {
        return docSnapshot.data();
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDocument<DataType>(
    path: string,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .delete();
    } catch (error) {
      console.error(`Error deleting document at path: ${path}\n`, error);
    }
  }


}
