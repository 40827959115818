import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import * as UserAccessActions from './user-access.actions';
import {IGroupAccess} from '../../../shared/shared-models/user-access/group-access';

export interface UserAccessState extends IInitialState {
  isLoading: boolean;
  groupAccess: IGroupAccess;
}

export const initialUserAccessState: UserAccessState = {
  // Initial --------------------------------
  errors: [],
  // UserAccess ------------------------------
  isLoading: false,
  groupAccess: {},
};

const createUserAccessReducer: ActionReducer<UserAccessState> = createReducer(
  initialUserAccessState,
  on(UserAccessActions.setUserAccessLoading, (state, {isLoading}) => (
    {
      ...state,
      isLoading,
    }
  )),
  on(UserAccessActions.sendInviteLink, (state, _) => (
    {
      ...state,
      isLoading: true,
    }
  )),
  on(UserAccessActions.sendInviteLinkSuccess, (state, _) => (
    {
      ...state,
      isLoading: false,
    }
  )),
  on(UserAccessActions.sendInviteLinkFailure, (state, {error}) => (
    {
      ...state,
      isLoading: false,
      errors: [...state.errors, error],
    }
  )),
  on(UserAccessActions.setPermissionsFailure, (state, {error}) => (
    {
      ...state,
      isLoading: false,
      errors: [...state.errors, error],
    }
  )),
  on(UserAccessActions.setColleaguesUserAccess, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(UserAccessActions.setColleaguesUserAccessSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(UserAccessActions.setColleaguesUserAccessFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    errors: [...state.errors, error],
  })),

  on(UserAccessActions.getGroupAccessSettings, (state) => ({
    ...state,
  })),
  on(UserAccessActions.getGroupAccessSettingsSuccess, (state, {groupAccess}) => ({
    ...state,
    groupAccess,
  })),
  on(UserAccessActions.getGroupAccessSettingsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
  // ====================================================================================================
  //
  // ====================================================================================================
);

export const userAccessReducer = (state: UserAccessState, action: Action): UserAccessState => createUserAccessReducer(state, action);
