import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {ITypeSenseApiData} from '../../shared-models/type-sense/type-sense-types';
import {IStore} from '../../shared-models/store/store';
import {path_stores_storeId_docs_typesense_key} from '../database-paths';

@Injectable({
  providedIn: 'root',
})
export class CollectionStoresDocsService {
  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  async getAllTypesenseApiKeysForStores(stores: IStore[]): Promise<{ [storeId: string]: ITypeSenseApiData }> {
    const allDocs: { [storeId: string]: ITypeSenseApiData } = {};

    await Promise.all(stores.map(async (store: IStore) => {
      const storeId = store.storeId;
      const docRef = this.angularFirestore.doc(path_stores_storeId_docs_typesense_key(storeId)).ref;
      const docSnapshot = await docRef.get();
      if (docSnapshot.exists) {
        allDocs[storeId] = docSnapshot.data() as ITypeSenseApiData;
      }
    }));

    return allDocs;
  }


}
