import {Component, ElementRef, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Icons} from '../../../shared-icons/icons';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NumberUtils} from '../../../../shared-utilities/utils-old/shared-utils-old/number-utils';
import {
  updatePriceGpForNgpReport,
  updateSingleVisibleField,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {selectNGPVisibleFields} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {selectStockMangerVisibleFields} from '../../../../features/stock-manager/store/stock-manager.selectors';
import {
  setPriceGpForStockManager,
  updateStockManagerSingleVisibleField,
} from '../../../../features/stock-manager/store/stock-manager.actions';
import {getDeepCopyOfObject} from '../../../../shared/shared-utils/object/object.utils';

@Component({
  selector: 'app-cell-editor-price-ngp',
  templateUrl: './cell-editor-price-ngp.component.html',
  styleUrls: ['./cell-editor-price-ngp.component.scss'],
})
export class CellEditorPriceNgpComponent implements ICellEditorAngularComp {

  @ViewChild('input', {static: true}) input: ElementRef;

  value: number | string;
  params: ICellEditorParams;
  width: string;
  colDefField: string;
  currentVisibleFields: { [key: string]: boolean } = {};
  currentPage: string;

  visibleFields$: Observable<{ [key: string]: boolean }>;
  currentPage$: Observable<string>;


  readonly icons: typeof Icons = Icons;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellEditorParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = +this.params.data[this.colDefField];
    this.width = `${this.params.colDef.width}px`;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
    if (this.currentPage === 'ngp-report') {
      this.visibleFields$ = this.store.select(selectNGPVisibleFields);
    } else {
      this.visibleFields$ = this.store.select(selectStockMangerVisibleFields);
    }
    this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
      this.currentVisibleFields = {...visibleFields};
    });

  }

  getValue(): number | string {
    if (this.params.value !== this.params.data.originalValue[this.colDefField].value) {
      this.params.data = getDeepCopyOfObject(this.params.data);
      this.params.data.isEdited = true;
    } else if (this.params.value === this.params.data.originalValue[this.colDefField].value) {
      this.params.data.isEdited = false;
    }

    const newValue = +NumberUtils.formatNumberToDecimals(+this.value, 2);
    switch (this.colDefField) {
      case 'sellPriIncl1':
        this.params.data[this.colDefField] = newValue;
        this.updateVisibleField('sellPriIncl1');
        break;
      case 'nominalGP':
        this.updateVisibleField('nominalGP');
        break;
      default:
        break;
    }
    if (+this.value === 0 || String(this.value) === '' || this.value === undefined || this.value === null || isNaN(+this.value)) {
      this.params.data[this.colDefField] = this.params.data.originalValue[this.colDefField].value;
      this.value = this.params.data.originalValue[this.colDefField].value;
    }
    if (this.currentPage === 'ngp-report') {
      this.store.dispatch(updatePriceGpForNgpReport({ngpReport: this.params.data, field: this.colDefField}));
      GridUtils.updateIsEditedAndForceRefresh(this.params);
      return +NumberUtils.formatNumberToDecimals(+this.value, 2);
    } else {
      this.params.data[this.colDefField] = Number(this.value);
      this.store.dispatch(setPriceGpForStockManager({stockItem: this.params.data, field: this.colDefField}));
      GridUtils.updateIsEditedAndForceRefresh(this.params);
      return +this.value;
    }

  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    this.params.value = this.value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, this.colDefField, this.store, this.currentPage);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    this.getValue();
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value.toString().length);
      this.input.nativeElement.select();
    }, 1);
  }

  updateVisibleField(field: string): void {
    switch (field) {
      case 'sellPriIncl1':
        if (!this.currentVisibleFields.nominalGP) {
          if (this.currentPage === 'ngp-report') {
            this.store.dispatch(updateSingleVisibleField({
              colId: 'nominalGP',
              value: true,
            }));
          } else {
            this.store.dispatch(updateStockManagerSingleVisibleField({
              colId: 'nominalGP',
              value: true,
            }));
          }

        }
        break;
      case 'nominalGP':
        if (!this.currentVisibleFields.sellPriIncl1) {
          if (this.currentPage === 'ngp-report') {
            this.store.dispatch(updateSingleVisibleField({
              colId: 'sellPriIncl1',
              value: true,
            }));
          } else {
            this.store.dispatch(updateStockManagerSingleVisibleField({
              colId: 'sellPriIncl1',
              value: true,
            }));
          }
        }
        break;
      default:
        break;
    }
  }

}
