import {SettingsStockManagerComponent} from './settings-stock-manager/settings-stock-manager.component';
import {
  SettingsStockManagerContainerComponent,
} from './settings-stock-manager-container/settings-stock-manager-container.component';
import {SettingsGeneralGridComponent} from './settings-general-grid/settings-general-grid.component';
import {
  SettingsGeneralGridContainerComponent,
} from './settings-general-grid-container/settings-general-grid-container.component';
import {SettingsStockTagsComponent} from './settings-stock-tags/settings-stock-tags.component';


export const SETTINGS_STOCK_MANAGER = SettingsStockManagerComponent;
export const SETTINGS_STOCK_MANAGER_CONTAINER = SettingsStockManagerContainerComponent;
export const SETTINGS_GENERAL_GRID_CONTAINER = SettingsGeneralGridContainerComponent;
export const SETTINGS_GENERAL_GRID = SettingsGeneralGridComponent;
export const SETTINGS_STOCK_TAGS = SettingsStockTagsComponent;

export const STORE_SETTINGS_COMPONENTS = [
  SETTINGS_STOCK_MANAGER,
  SETTINGS_STOCK_MANAGER_CONTAINER,
  SETTINGS_GENERAL_GRID,
  SETTINGS_GENERAL_GRID_CONTAINER,
  SETTINGS_STOCK_TAGS,
];
