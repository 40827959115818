import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import {IStore} from '../../shared-models/store/store';
import {IAdvancedFilterGroup} from '../../shared-models/filter-groups/filter-groups';
import {path_stores_storeId_settings_advanced_filter_groups} from '../database-paths';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class CollectionStoresSettingsService {
  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  // ====================================================================================================
  // Generic Get/Set/Delete
  // ====================================================================================================

  async setDocument<DataType>(
    path: string,
    data: DataType,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .set(data, {merge: true});
    } catch (error) {
      console.error(error);
    }
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.exists) {
        return docSnapshot.data();
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDocument<DataType>(
    path: string,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .delete();
    } catch (error) {
      console.error(`Error deleting document at path: ${path}\n`, error);
    }
  }

  // ====================================================================================================
  // Advanced Filter Group/s
  // ====================================================================================================

  getStoreSettingsAdvancedFilterGroups(store: IStore): Promise<IAdvancedFilterGroup[]> {
    return new Promise<IAdvancedFilterGroup[]>((resolve, reject): void => {
      this.angularFirestore.collection<IAdvancedFilterGroup>(
        path_stores_storeId_settings_advanced_filter_groups(store.storeId),
        (ref: CollectionReference<IAdvancedFilterGroup[]>) => ref.orderBy('id', 'desc'),
      )
        .get()
        .toPromise()
        .then((querySnapshot: QuerySnapshot<IAdvancedFilterGroup>): void => {
          const advancedFilters: IAdvancedFilterGroup[] = [];
          querySnapshot.forEach((doc: QueryDocumentSnapshot<IAdvancedFilterGroup>): void => {
            const data = doc.data();
            advancedFilters.push(data);
          });
          resolve(advancedFilters);
        })
        .catch((error): void => {
          const newError = {
            ...error,
            message: `Error getting stores: Store/Settings/Advanced Filters.\n${error.message}`,
          };
          reject(newError);
        });
    });
  }

  async arrayUnionField<T>(path: string, fieldName: string, values: T[]): Promise<void> {
    const docRef = this.angularFirestore.doc(path);

    try {
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.exists) {
        await docRef.update({
          [fieldName]: firebase.firestore.FieldValue.arrayUnion(...values),
        });
      } else {
        await docRef.set({
          [fieldName]: values,
        });
      }
    } catch (error) {
      console.error(`Error performing arrayUnion on field "${fieldName}":`, error);
    }
  }


  async arrayRemoveField<T>(path: string, fieldName: string, values: T[]): Promise<void> {
    try {
      await this.angularFirestore.doc(path).update({
        [fieldName]: firebase.firestore.FieldValue.arrayRemove(...values),
      });
    } catch (error) {
      console.error(`Error performing arrayRemove on field "${fieldName}":`, error);
      throw error;
    }
  }


}
