import {
  HeaderMenuColumnData,
  StoreHeaderMenuData,
} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';
import {IStore} from '../../../shared/shared-models/store/store';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {MenuUtils} from '../../../shared-utilities/utils-old/grid-utils-old/menu-utils';
import {GridHeaderMenuTypeChange} from '../../../shared/shared-models/grid-menu/header-menu-type-changes';
import {IGridHeaderMenu} from '../../../shared/shared-models/grid-menu/header-menu';
import {DisabledRules, StockItem} from '../../../shared-utilities/models-old/datastructures';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {ReportUtils} from '../../../shared-utilities/utils-old/shared-utils-old/report-utils';

/**
 * Updates the store header menu data for a specific store's price banding based on the provided filters and tools settings.
 *
 * This function takes the current state of `storeHeaderMenuData`, a list of `priceBandingCols`, and user store data,
 * and returns an updated deep copy of `storeHeaderMenuData`. It applies the `isPriceBanding` setting to the specified
 * columns in the store's header menu data based on the `filtersAndTools` configuration.
 *
 * @param {FiltersAndTools} filtersAndTools - An object containing filters and tools settings, including the price banding setting.
 * @param {string[]} priceBandingCols - An array of column keys that are subject to price banding.
 * @param {StoreHeaderMenuData} storeHeaderMenuData - The current store header menu data that needs to be updated.
 * @param {IStore} userStore - The store data object that includes the store ID to be used for identifying the relevant store.
 *
 * @returns {StoreHeaderMenuData} - A deep copy of the updated store header menu data with applied changes.
 *
 * @example
 * const filtersAndTools = { isApplyPriceBandingOn: true };
 * const priceBandingCols = ['col1', 'col2'];
 * const storeHeaderMenuData = { 'store123': { 'col1': { isPriceBanding: false } } };
 * const userStore = { storeId: 'store123' };
 *
 * const updatedData = setNGPReportStoreHeaderMenuDataFromFiltersAndTools(filtersAndTools, priceBandingCols, storeHeaderMenuData, userStore);
 * // Output: { 'store123': { 'col1': { isPriceBanding: true }, 'col2': { isPriceBanding: true } } }
 */
export function setNGPReportStoreHeaderMenuDataFromFiltersAndToolsForPriceBanding(
  filtersAndTools: FiltersAndTools,
  priceBandingCols: string[],
  storeHeaderMenuData: StoreHeaderMenuData,
  userStore: IStore,
): StoreHeaderMenuData {
  const storeHeaderMenuDataCopy: StoreHeaderMenuData = getDeepCopyOfObject<StoreHeaderMenuData>(storeHeaderMenuData);
  if (!storeHeaderMenuDataCopy[userStore.storeId]) {
    storeHeaderMenuDataCopy[userStore.storeId] = {};
  }
  if (Object.keys(storeHeaderMenuDataCopy[userStore.storeId]).length > 0) {
    Object.keys(storeHeaderMenuDataCopy[userStore.storeId]).forEach((colKey: string): void => {
      if (colKey && priceBandingCols.includes(colKey)) {
        storeHeaderMenuDataCopy[userStore.storeId][colKey].isPriceBanding = filtersAndTools.isApplyPriceBandingOn;
      }
    });
  } else {
    priceBandingCols.forEach((colKey: string): void => {
      if (!storeHeaderMenuDataCopy[userStore.storeId][colKey]) {
        storeHeaderMenuDataCopy[userStore.storeId][colKey] = {
          isPriceBanding: filtersAndTools.isApplyPriceBandingOn,
        } as HeaderMenuColumnData;
      }
    });
  }
  return storeHeaderMenuDataCopy;
}

/**
 * Updates the store header menu actions based on the provided grid header menu changes.
 *
 * This function creates a deep copy of the `StoreHeaderMenuData` and applies updates based on the type of change
 * (sorting, hiding, editing, or price banding) indicated in the `gridHeaderMenu`. It handles sorting, visibility changes,
 * editing column values, and price banding adjustments for specific columns in the grid.
 *
 * @param {IStore} userStore - The current store details.
 * @param {StoreHeaderMenuData} stateHeaderMenuData - The current state of the store's header menu data.
 * @param {string[]} priceBandingCols - An array of column IDs that require price banding.
 * @param {IGridHeaderMenu} gridHeaderMenu - Object that contains information about the type of change, column ID, and value.
 * @returns {StoreHeaderMenuData} - A new `StoreHeaderMenuData` object with the updated header menu actions.
 *
 * @template StoreHeaderMenuData - The type of the store header menu data.
 *
 * @example
 * const updatedHeaderMenuData = setNGPReportStoreHeaderMenuActions(
 *   userStore, stateHeaderMenuData, ['priceCol1', 'priceCol2'], gridHeaderMenu
 * );
 */
export function setNGPReportStoreHeaderMenuActions(
  userStore: IStore,
  stateHeaderMenuData: StoreHeaderMenuData,
  priceBandingCols: string[],
  gridHeaderMenu: IGridHeaderMenu,
): StoreHeaderMenuData {
  let storeHeaderMenuDataCopy: StoreHeaderMenuData = getDeepCopyOfObject<StoreHeaderMenuData>(stateHeaderMenuData);
  switch (gridHeaderMenu.typeOfChange) {
    case GridHeaderMenuTypeChange.isSorting:
      storeHeaderMenuDataCopy = MenuUtils.updateSortTypeForAllColumns(storeHeaderMenuDataCopy, gridHeaderMenu.colId, gridHeaderMenu.sort, userStore.storeId);
      break;
    case GridHeaderMenuTypeChange.isHiding:
    case GridHeaderMenuTypeChange.isEditing:
      storeHeaderMenuDataCopy = setHeaderMenuColumnDataValue(
        gridHeaderMenu.colId, gridHeaderMenu.value, storeHeaderMenuDataCopy, userStore, gridHeaderMenu.typeOfChange,
      );
      break;
    case GridHeaderMenuTypeChange.isPriceBanding:
      priceBandingCols.forEach((col: string): void => {
        storeHeaderMenuDataCopy = setHeaderMenuColumnDataValue(
          col, gridHeaderMenu.value, storeHeaderMenuDataCopy, userStore, gridHeaderMenu.typeOfChange,
        );
      });
      break;
  }
  return storeHeaderMenuDataCopy;
}

/**
 * Updates a specific property value for a column in the store header menu data.
 *
 * This function checks if the specified store and column exist within the `storeHeaderMenuData`. If they do not exist,
 * it initializes them. It then updates or sets the property specified by `typeChange` with the provided `menuDataValue`.
 * The function returns an updated store header menu data object.
 *
 * @template MenuDataType - The type of the value being set for the specified property.
 * @param {string} columnId - The identifier of the column to be updated.
 * @param {MenuDataType} menuDataValue - The value to set for the specified property.
 * @param {StoreHeaderMenuData} storeHeaderMenuData - The current store header menu data that needs to be updated.
 * @param {IStore} userStore - The store object containing the `storeId` used to identify the relevant store.
 * @param {string} typeChange - The type of property to update within the column (e.g., 'sortType', 'hide', 'isEditing', 'isPriceBanding').
 *
 * @returns {StoreHeaderMenuData} - An updated store header menu data object.
 *
 * @example
 * const columnId = 'column1';
 * const menuDataValue = 'asc'; // or boolean, null, etc. depending on `typeChange`
 * const userStore = { storeId: 'store123' };
 * const storeHeaderMenuData = {
 *   'store123': {
 *     'column1': { sortType: 'desc', hide: false, isEditing: true }
 *   }
 * };
 * const updatedData = setHeaderMenuColumnDataValue(columnId, menuDataValue, storeHeaderMenuData, userStore, 'sortType');

 * // Output: { 'store123': { 'column1': { sortType: 'asc', hide: false, isEditing: true } } }
 */
export function setHeaderMenuColumnDataValue<MenuDataType>(
  columnId: string,
  menuDataValue: MenuDataType,
  storeHeaderMenuData: StoreHeaderMenuData,
  userStore: IStore,
  typeChange: string,
): StoreHeaderMenuData {
  if (!storeHeaderMenuData[userStore.storeId]) {
    storeHeaderMenuData[userStore.storeId] = {};
  }
  if (!storeHeaderMenuData[userStore.storeId][columnId]) {
    storeHeaderMenuData[userStore.storeId][columnId] = {};
  }
  storeHeaderMenuData = {
    ...storeHeaderMenuData,
    [userStore.storeId]: {
      ...storeHeaderMenuData[userStore.storeId],
      [columnId]: {
        ...storeHeaderMenuData[userStore.storeId][columnId],
        [typeChange]: menuDataValue,
      },
    },
  } as StoreHeaderMenuData;
  return storeHeaderMenuData;
}

/**
 * Updates a property in the `FiltersAndTools` object based on a specified action type change.
 *
 * This function takes a `FiltersAndTools` object, a property name, a type change identifier, and a value,
 * and returns a deep copy of the `FiltersAndTools` object with the specified property updated according to the type change.
 *
 * @template MenuDataType - The type of the value being assigned to the property in `FiltersAndTools`.
 * @param {FiltersAndTools} filtersAndTools - The current `FiltersAndTools` object that needs to be updated.
 * @param {string} property - The name of the property in `FiltersAndTools` to be updated.
 * @param {string} typeChange - The type of change to apply, currently supporting `'isPriceBanding'`.
 * @param {MenuDataType} value - The value to set for the specified property based on the type change.
 *
 * @returns {FiltersAndTools} - A deep copy of the updated `FiltersAndTools` object.
 *
 * @example
 * const filtersAndTools = { isApplyPriceBandingOn: false };
 * const updatedFilters = setFiltersAndToolsPropertyByHeaderMenuActionTypeChange(filtersAndTools, 'isApplyPriceBandingOn', 'isPriceBanding', true);
 * console.warn(updatedFilters);
 * // Output: { isApplyPriceBandingOn: true }
 */
export function setFiltersAndToolsPropertyByHeaderMenuActionTypeChange<MenuDataType>(
  filtersAndTools: FiltersAndTools,
  property: string,
  typeChange: string,
  value: MenuDataType,
): FiltersAndTools {
  const filtersAndToolsCopy: FiltersAndTools = getDeepCopyOfObject<FiltersAndTools>(filtersAndTools);
  switch (typeChange) {
    case 'isPriceBanding':
      filtersAndToolsCopy[property] = value;
      break;
  }
  return filtersAndToolsCopy;
}

export const checkForDisabledItems = (stockItems: StockItem[], ngpReports: NGPReport[], rulesDoc: DisabledRules, setOriginalValue?: boolean): NGPReport[] => {
  const stockItemMap = new Map<string, StockItem>();
  stockItems.forEach((stockItem: StockItem) => {
    stockItemMap.set(stockItem.stockId, stockItem);
  });

  ngpReports.forEach((ngpReport: NGPReport) => {
    const matchingStockItem = stockItemMap.get(ngpReport.stockId);
    ngpReport.disabled = ReportUtils.determineIfItemIsDisabled(matchingStockItem, rulesDoc);
    if (setOriginalValue) {
      ngpReport.originalValue['disabled'].value = ngpReport.disabled;
    }
  });

  return ngpReports;
};

export const checkForDisabledItem = (
  stockItems: StockItem[],
  ngpReport: NGPReport,
  rulesDoc: DisabledRules,
): NGPReport => {
  let matchingStockItem = stockItems.find(
    (item: StockItem) => item.stockId === ngpReport.stockId,
  );
  matchingStockItem = {...matchingStockItem};
  matchingStockItem.desc = ngpReport.desc;

  if (matchingStockItem) {
    ngpReport.disabled = ReportUtils.determineIfItemIsDisabled(
      matchingStockItem,
      rulesDoc,
    );
  }

  return ngpReport;
};


export function mergeNgpReportsFromLocalStorage(
  storeId: string,
  data: { [stockId: string]: NGPReport },
  storageKey: string,
): { [stockId: string]: NGPReport } {
  try {
    const serializedData = localStorage.getItem(storageKey);

    if (serializedData !== null) {
      const savedEdits = JSON.parse(serializedData);

      if (savedEdits[storeId]) {
        const storeSavedEdits: { [stockId: string]: NGPReport } = savedEdits[storeId];

        for (const [stockId, savedReport] of Object.entries(storeSavedEdits)) {
          if (data[stockId]) {
            data[stockId] = {
              ...data[stockId],
              ...savedReport,
            };
          } else {
            data[stockId] = savedReport;
          }
        }
      }
    }
  } catch (error: unknown) {
    console.error("Error reading from localStorage:", error);
  }

  return data;
}
