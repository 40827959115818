import {ModalController} from '@ionic/angular';
import {ISharedModalBasic, ISharedModalBasicResponse} from '../../shared-models/modals/shared-modal-basic';
import {
  SharedModalBasicComponent,
} from '../../shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {OverlayEventDetail} from '@ionic/core';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedModalUtils {

  constructor(
    private modalController: ModalController,
  ) {
  }

  async createModal(modalProps: ISharedModalBasic): Promise<OverlayEventDetail> {
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent,
      componentProps: modalProps,
      cssClass: [...modalProps.cssClass ?? 'shared-basic-modal-css'],
    });
    await modalController.present();
    const returnValue: OverlayEventDetail<ISharedModalBasicResponse> = await modalController.onDidDismiss();
    return returnValue;
  }

}
