// Todo: Storm: To read and check validity of comments.
/**
 * @interface NGPReport
 * An object of an item within an NGP Report.
 *
 * @member {string} stockId The id for the stock item.
 * @member {string} desc The description for the stock item.
 * @member {boolean} newlyAdded Is this a newly added stock item?
 * @member {Date} manuallyAdded The date the item was manually added.
 * @member {boolean} isSelected Is this item selected within the grid.
 * @member {boolean} isEdited Is this item edited within the grid.
 * @member {boolean} error has this item caused an error in the grid.
 * @member {object} originalValue the original value of an edited item.
 * @member {string} storeId The id for the store that owns the stock item.
 * @member {string} store The name for the store that owns the stock item.
 * @member {number} latestCost The cost price for the stock item.
 * @member {boolean} costMiss Is this stock item a cost miss? Cost miss is calculated (latest cost !== last GRV price).
 * @member {boolean} costMiss Is this stock item a cost miss?
 * @member {string} regularSuppCode The supplier code for the stock item.
 * @member {any} suppCode The supplier stock code for the stock item.
 * @member {Date} suppUsedLastDate1 The GRV date for the stock item.
 * @member {Date} suppUsedLastDate2 The previous GRV date for the stock item.
 * @member {string} dep The department the stock code falls into.
 * @member {number} sellPriIncl1 The selling price including VAT for the stock item.
 * @member {number} sellPriExcl1 The selling price excluding VAT for the stock item.
 * @member {number} gp1 The GP (avg) cost for the stock item.
 * @member {number} diffGP The % difference for the GP.
 * @member {number} nominalGP The nominal GP for the stock item.
 * @member {number} prevGP The previous GP for the stock item.
 * @member {number} lineColourCode The stock item line colour code for the grid.
 * @member {string} lineColour The line colour code combined with the store id for the stock item.
 * @member {string} lineColourValue The value for the line colour code.
 * @member {string} lineColourDescription The description for the line colour code.
 * @member {number} packSize The pack size of the stock item.
 * @member {number} vatR The VAT number for the stock item.
 * @member {number} daysDiff The difference in days for the suppUsedLastDate1 and suppUsedLastDate2.
 * @member {number} unitsYear The count of the units sold in the last year (12 months) for the stock item.
 * @member {Date} createdDate The date the stock item was created on the system.
 * @member {boolean} created Is the stock item created?
 * @member {boolean} cratedHasSupp1 Does the created stock item have a supplier?
 */
export interface NGPReport {
  // General ----------------------------
  /** @member {string} stockId The id for the stock item. */
  stockId?: string;
  /** @member {string} desc The description for the stock item. */
  desc: string;
  /** @member {boolean} newlyAdded Is this a newly added stock item? */
  newlyAdded?: boolean;
  /** @member {Date} manuallyAdded The date the item was manually added. */
  manuallyAdded?: Date;
  /** @member {boolean} isSelected Is this item selected within the grid. */
  isSelected: boolean;
  /** @member {boolean} isEdited Is this item edited within the grid. */
  isEdited: boolean;
  /** @member {boolean} error has this item caused an error in the grid. */
  error: boolean;
  /** @member {object} originalValue the original value of an edited item. */
  originalValue: { [key: string]: { value: string | boolean } };
  // Store ------------------------------
  /** @member {string} storeId The id for the store that owns the stock item. */
  storeId?: string;
  /** @member {string} store The name for the store that owns the stock item. */
  store?: string;
  // Cost -------------------------------
  /** @member {number} latestCost The cost price for the stock item. */
  latestCost: number;
  /** @member {number} prevCostPrice The previous cost price for the stock item. */
  prevCostPrice: number;
  /** @member {boolean} costMiss Is this stock item a cost miss? Cost miss is calculated (latest cost !== last GRV price). */
  costMiss?: boolean;
  // Supplier ---------------------------
  /** @member {string} regularSuppCode The supplier code for the stock item. */
  regularSuppCode: string;
  /** @member {any} suppName The supplier name for the stock item. */
  suppName: any;
  /** @member {any} suppCode The name code for the stock item. */
  suppCode: any;
  /** @member {Date} suppUsedLastDate1 The GRV date for the stock item. */
  suppUsedLastDate1: Date | null;
  /** @member {Date} suppUsedLastDate2 The previous GRV date for the stock item. */
  suppUsedLastDate2: Date | null;
  // Department -------------------------
  /** @member {string} dep The department the stock code falls into. */
  dep: string;
  /** @member {string} subDep The Sub Department the stock code falls into. */
  subDep: string;
  // Price ------------------------------
  /** @member {number} sellPriIncl1 The selling price including VAT for the stock item. */
  sellPriIncl1: number;
  /** @member {number} sellPriExcl1 The selling price excluding VAT for the stock item.*/
  sellPriExcl1: number;
  // GP ---------------------------------
  /** @member {number} gp1 The GP (avg) cost for the stock item. */
  gp1: number;
  /** @member {number} diffGP The % difference of the GP for the stock item. */
  diffGP: number;
  /** @member {number} nominalGP The nominal GP for the stock item. */
  nominalGP: number;
  /** @member {number} prevGP The previous GP for the stock item. */
  prevGP: number;
  // UI ---------------------------------
  /** @member {number} lineColourCode The stock item line colour code for the grid. */
  lineColourCode: number;
  /** @member {string} lineColour The line colour code combined with the store id for the stock item. */
  lineColour?: string;
  /** @member {string} lineColourValue The value for the line colour code. */
  lineColourValue?: string;
  /** @member {string} lineColourDescription The description for the line colour code. */
  lineColourDescription?: string;
  // Package ----------------------------
  /** @member {number} packSize The pack size of the stock item. */
  packSize: number;
  // VAT --------------------------------
  /** @member {number} vatR The VAT number for the stock item. */
  vatR: number;
  // Days -------------------------------
  /** @member {number} daysDiff The difference in days for the suppUsedLastDate1 and suppUsedLastDate2. */
  daysDiff: number;
  // Statistics -------------------------
  /** @member {number} unitsYear The count of the units sold in the last year (12 months) for the stock item. */
  unitsYear: number;
  /** @member {Date} createdDate The date the stock item was created on the system. */
  createdDate?: Date;
  /** @member {boolean} created Is the stock item created? */
  created?: boolean;
  /** @member {boolean} disabled Is the stock item disabled according to the defined rules? */
  disabled?: boolean;
  /** @member {boolean} cratedHasSupp1 Does the created stock item have a supplier? */
  cratedHasSupp1?: boolean; // Todo: Remove spelling mistake on original model and here
  /** @member {number} onHand amount of the stock item on hand */
  onHand: number;
  /** @member {number} onHoldCode code used to check if the item is disabled or not */
  onHoldCode: number;
  /** @member {string[]} _tags tag used to check for spefic conditions */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _tags: string[];
}

// This should have all properties listed
/**
 * @this blankNGPReport is an empty object on an `NGPReport` object.
 * This can be used as an object for verification purposes, etc.
 *
 */
export const blankNGPReport: NGPReport = {
  stockId: '',
  desc: '',
  newlyAdded: true,
  disabled: false,
  manuallyAdded: new Date(),
  isSelected: false,
  isEdited: false,
  error: false,
  originalValue: {},
  storeId: '',
  store: '',
  latestCost: 0,
  prevCostPrice: 0,
  costMiss: false,
  regularSuppCode: '',
  suppName: '',
  suppCode: '',
  suppUsedLastDate1: new Date(),
  suppUsedLastDate2: new Date(),
  dep: '',
  subDep: '',
  sellPriIncl1: 0,
  sellPriExcl1: 0,
  gp1: 0,
  diffGP: 0,
  nominalGP: 0,
  prevGP: 0,
  lineColourCode: 0,
  lineColour: '',
  lineColourValue: '',
  lineColourDescription: '',
  packSize: 0,
  vatR: 0,
  daysDiff: 0,
  unitsYear: 0,
  createdDate: new Date(),
  created: false,
  cratedHasSupp1: false,
  onHand: 0,
  onHoldCode: 0,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _tags: [],
};
