import {IAutoOrderOrderSettingsFirestore} from "../../../shared/shared-models/auto-ordering/auto-order-order";

export type MessageType = 'AUTO_ORDERS_U_UPDATE';

export enum MessageTypesEnum {
  'AUTO_ORDERS_U_UPDATE' = 'AUTO_ORDERS_U_UPDATE'
}

export interface IMessage<DataType> {
  payload: {
    data: {
      [key: string]: DataType
    }
  }
  sender: string;
  timestamp: Date,
  type: MessageType
}

export interface IMessageAutoOrderUpdate {
  [supplierId: string]: IAutoOrderOrderSettingsFirestore & {
    rank: number,
    userId: string
  }
}
